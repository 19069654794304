import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import dateFormat from 'dateformat';

import FilterDataGrid from '../util/FilterDataGrid';

const TIMESTAMP_FORMAT = 'yyyy-mm-dd h:MM:ss TT';

var dateFormatter = (params) => (params.value === null) ? '-' : dateFormat(params.value, TIMESTAMP_FORMAT)
var nullFormatter = (params) => (params.value === null || params.value === '') ? '-' : params.value;
var licensePlateFormatter = (params) => `${params.value.slice(0, 3)} ${params.value.slice(3)}`

const vehiclesColumns = [
    { field: 'license_plate', headerName: 'License Plate', flex: 1, valueFormatter: licensePlateFormatter },
    { field: 'main_unit_sn', headerName: 'Main Unit', flex: 1, valueFormatter: nullFormatter },
    { field: 'tilt_module_a_sn', headerName: 'Tilt Module A', flex: 1, valueFormatter: nullFormatter },
    { field: 'tilt_module_b_sn', headerName: 'Tilt Module B', flex: 1, valueFormatter: nullFormatter },
    { field: 'timestamp', headerName: 'Install Date/Time', flex: 1, valueFormatter: dateFormatter },
];

const eventsColumns = [
    { field: 'timestamp', headerName: 'Timestamp', flex: 1, valueFormatter: dateFormatter },
    { field: 'install', headerName: 'Install / Uninstall', flex: 1, valueFormatter: (params) => params.value ? 'INSTALL' : 'UNINSTALL' },
    { field: 'license_plate', headerName: 'License Plate', flex: 1, valueFormatter: licensePlateFormatter },
    { field: 'main_unit_sn', headerName: 'Main Unit', flex: 1, valueFormatter: nullFormatter },
    { field: 'notes', headerName: 'Notes', flex: 1, valueFormatter: nullFormatter },
]

class InstallationsLists extends Component
{
    render()
    {
        const path = this.props.match.path;

        return (
            <Box display='flex' flexDirection='column' flex={this.props.flex}>
                <Box ml={2} mr={2} mt={2}>
                    <Paper>
                        <Tabs
                            value={this.props.location.pathname}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='fullWidth'>

                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/vehicles`} value={`${this.props.match.url}/vehicles`} label='Installed Vehicles' />
                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/events`} value={`${this.props.match.url}/events`} label='Event Log' />

                        </Tabs>
                    </Paper>
                </Box>

                <Box flex={1} m={2}>
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${path}/vehicles`}/>
                        </Route>
                        <Route path={`${path}/vehicles`}>
                            <FilterDataGrid key='Installed Vehicles' title='Rothar - Installations - Installed Vehicles' data={this.props.vehicles} columns={vehiclesColumns}/>
                        </Route>
                        <Route path={`${path}/events`}>
                            <FilterDataGrid key='Event Log' title='Rothar - Installations - Event Log' data={this.props.events} columns={eventsColumns}/>
                        </Route>
                    </Switch>
                </Box>
            </Box>
        )
    }
}

export default withRouter(InstallationsLists);
