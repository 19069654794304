import React, { Component } from 'react';

import { Map, LayersControl, TileLayer, LayerGroup, Polygon, Polyline, ScaleControl } from 'react-leaflet'

import { parkadeBounds, destination } from './ParkadeMapUtils';

import './ParkadeMap.css';

const EVO_BLUE = '#00bce2'

const HYSTERESIS = 10;

/**
 * Leaflet-based map with parkades plotted over top.
 * 
 * Properties:
 *      mapCenter: [lat, lng]
 *      mapZoom: int
 *      mapCursor: String
 *      parkades: [Parkade]
 *      selectedId: int
 *      handleParkadeClick: (event, id) => {}
 *      handleMapClick: (event) => {}
 */

class ParkadeMap extends Component
{
    renderBaseLayer = (id) =>
    {
        return (
            <TileLayer
                url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'
                attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                maxZoom={21}
                id={id}
                tileSize={512}
                zoomOffset={-1}
                accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN} />
        )
    }

    renderParkade = (parkade) =>
    {
        var centerLat = parseFloat(parkade.center_lat);
        var centerLng = parseFloat(parkade.center_lng);
        var width = parseFloat(parkade.width);
        var height = parseFloat(parkade.height);
        var rotation = parseFloat(parkade.rotation);

        var positions = parkadeBounds([centerLat, centerLng], width, height, rotation);
        if (positions == null) return null;

        var expandedPositions = parkadeBounds([centerLat, centerLng], (width + 2*HYSTERESIS), (height + 2*HYSTERESIS), rotation);

        var color = parkade.evo_parking ? EVO_BLUE : 'darkorange';
        var selected = this.props.selectedId === parkade.id;
        var opacity = selected ? 0.5 : 0.25;

        return (
            <LayerGroup key={`parkade-${parkade.id}`}>
                <Polygon
                    key={`parkade-${parkade.id}-hysteresis`}
                    positions={expandedPositions}
                    dashArray='8'
                    color='green'
                    fillOpacity={0} />

                <Polygon
                    key={`parkade-${parkade.id}-rect`}
                    positions={positions}
                    color={color}
                    fillColor='darkorange'
                    fillOpacity={opacity}
                    onClick={(event) => {if (this.props.handleParkadeClick) this.props.handleParkadeClick(event, parkade.id);}} />
            </LayerGroup>
        );
    }

    renderParkadeHeading = (parkade) =>
    {
        var start = [parseFloat(parkade.center_lat), parseFloat(parkade.center_lng)];
        var heading = parseFloat(parkade.alert_heading);
        var tolerance = parseFloat(parkade.alert_heading_tolerance);

        var headingEnd1 = destination(start, 50, heading - tolerance);
        var headingEnd2 = destination(start, 50, heading + tolerance);

        if (start == null || headingEnd1 == null || headingEnd2 == null) return null;

        return (
            <Polyline
                key={`parkade-${parkade.id}-heading`}
                positions={[headingEnd1, start, headingEnd2]}
                dashArray='8'
                color={'black'} />
        )
    }

    render()
    {
        var streetMap = this.renderBaseLayer('mapbox/streets-v11');
        var satelliteMap = this.renderBaseLayer('mapbox/satellite-streets-v11');

        var mapStyle = {
            position: 'relative',
            cursor: this.props.mapCursor
        };

        return (
            <Map
                center={this.props.mapCenter}
                zoom={this.props.mapZoom}
                onClick={this.props.handleMapClick}
                onMove={this.props.handleMapMove}
                style={mapStyle}>

                <LayersControl position="topright">
                    <LayersControl.BaseLayer name="Streets">
                        {streetMap}
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Satellite" checked>
                        {satelliteMap}
                    </LayersControl.BaseLayer>
                </LayersControl>

                <ScaleControl position="bottomleft" />

                <LayerGroup>
                    {this.props.parkades.map(parkade => this.renderParkadeHeading(parkade))}
                </LayerGroup>

                <LayerGroup>
                    {this.props.parkades.map(parkade => this.renderParkade(parkade))}
                </LayerGroup>

                {this.props.children}

            </Map>
        );
    }
}

export default ParkadeMap;