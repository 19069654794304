import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { styled } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const AppLauncherCard = styled(Card)({
    width: 225,
    borderRadius: 10,
    margin: 20,
});

/**
 * Properties:
 *      name: string
 *      icon: image resource
 *      target: string
 */

class AppLauncher extends Component
{
    state = {
    }

    async componentDidMount()
    {
    }

    render()
    {
        return (
            <AppLauncherCard raised>
                <CardActionArea component={Link} to={this.props.target} onClick={this.props.onClick}>
                    <CardContent>
                        <Box display='flex' m={2} justifyContent='center'>
                            <img src={this.props.icon} height='128px'/>
                        </Box>
                        <Box display='flex' m={0} justifyContent='center'>
                            <Typography variant="h5">
                                {this.props.name}
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </AppLauncherCard>
        )
    }
}

export default AppLauncher