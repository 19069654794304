import React, { Component } from 'react';

import Box from '@material-ui/core/Box';

import parkadesIcon from './img/Icon_Parkades.svg';
import devicesIcon from './img/Icon_Devices.svg';
import logsIcon from './img/Icon_Logs.svg';
import gpsIcon from './img/Icon_GPS.svg';
import releasesIcon from './img/Icon_Releases.svg';
import adminIcon from './img/Icon_Admin.svg';

import AppLauncher from './util/AppLauncher';
import BreadcrumbAppBar from './util/BreadcrumbAppBar';

class Dashboard extends Component
{
    state = {
    }

    async componentDidMount()
    {
    }

    render()
    {
        document.title = 'Rothar - Dashboard';

        return (
            <Box width={1}>

                <BreadcrumbAppBar/>

                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='center'
                    alignItems='center'
                    width={1}
                    height={1}
                    pt={8}>

                    <Box
                        display='flex'
                        flexWrap='wrap'
                        flexDirection='row'
                        justifyContent='center'
                        alignItems='center'
                        style={{maxWidth: 900}}>

                        <AppLauncher name='Parkades' icon={parkadesIcon} target='/parkades'/>
                        <AppLauncher name='Devices' icon={devicesIcon} target='/devices'/>
                        <AppLauncher name='Logs' icon={logsIcon} target='/logs'/>
                        <AppLauncher name='Releases' icon={releasesIcon} target='/releases'/>
                        <AppLauncher name='GPS Simulator' icon={gpsIcon} target='/gps_sim'/>
                        <AppLauncher name='Admin' icon={adminIcon} target='/admin/' onClick={(event) => {
                            event.preventDefault();
                            window.location.href = '/admin/'
                        }}/>

                    </Box>

                </Box>

            </Box>
        )
    }
}

export default Dashboard