import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

import Box from '@material-ui/core/Box';

import APIs from '../api/APIs';

import BreadcrumbAppBar from '../util/BreadcrumbAppBar';
import ReleasesLists from './ReleasesLists';
import ParkadesReleaseForm from './ParkadesReleaseForm';
import MainFirmwareReleaseForm from './MainFirmwareReleaseForm';
import TiltFirmwareReleaseForm from './TiltFirmwareReleaseForm';

var sortByIdDesc = (first, second) => {
    return first.id === second.id ? 0 : (first.id > second.id ? -1 : 1);
};

class ReleasesManager extends Component
{
    state = {
        parkadesReleases: [],
        mainFirmwareReleases: [],
        tiltFirmwareReleases: [],
    }

    refreshParkadesReleases = async () =>
    {
        var resp = await fetch(`${APIs.RELEASES.PARKADES}`);
        if (resp.status === 200)
        {
            var parkadesReleases = await resp.json();
            parkadesReleases.sort(sortByIdDesc);
            this.setState({
                parkadesReleases: parkadesReleases
            });
        }
    }

    refreshMainFirmwareReleases = async () =>
    {
        var resp = await fetch(APIs.RELEASES.MAIN_FIRMWARE);
        if (resp.status === 200)
        {
            var mainFirmwareReleases = await resp.json();
            mainFirmwareReleases.sort(sortByIdDesc);
            this.setState({
                mainFirmwareReleases: mainFirmwareReleases
            });
        }
    }

    refreshTiltFirmwareReleases = async () =>
    {
        var resp = await fetch(APIs.RELEASES.TILT_FIRMWARE);
        if (resp.status === 200)
        {
            var tiltFirmwareReleases = await resp.json();
            tiltFirmwareReleases.sort(sortByIdDesc);
            this.setState({
                tiltFirmwareReleases: tiltFirmwareReleases
            });
        }
    }

    refreshData = async () =>
    {
        await this.refreshParkadesReleases();
        await this.refreshMainFirmwareReleases();
        await this.refreshTiltFirmwareReleases();
    }

    handleParkadesReleaseCreated = async () =>
    {
        await this.refreshParkadesReleases();
    }

    checkParkadesReleaseVersionExists = (versionName) =>
    {
        return this.state.parkadesReleases.find((value) => {
            return (value.version === versionName)
        });
    }

    async componentDidMount()
    {
        await this.refreshData();
    }

    render()
    {
        document.title = 'Rothar - Releases';

        const path = this.props.match.path;

        return (
            <Box display='flex' flexDirection='column' minHeight={0} width={1}>
                <BreadcrumbAppBar/>

                <Box display='flex' flexDirection='row' width={1} height={1} pt={8}>

                    <ReleasesLists flex={1}
                        parkadesReleases={this.state.parkadesReleases}
                        mainFirmwareReleases={this.state.mainFirmwareReleases}
                        tiltFirmwareReleases={this.state.tiltFirmwareReleases} />

                    <Switch>
                        <Route path={`${path}/parkades`}>
                            <ParkadesReleaseForm width='400px'
                                checkVersionExists={this.checkParkadesReleaseVersionExists}
                                handleReleaseCreated={this.handleParkadesReleaseCreated}/>
                        </Route>
                        <Route path={`${path}/main_firmware`}>
                            <MainFirmwareReleaseForm width='400px'/>
                        </Route>
                        <Route path={`${path}/tilt_firmware`}>
                            <TiltFirmwareReleaseForm width='400px'/>
                        </Route>
                    </Switch>

                </Box>
            </Box>
        )
    }
}

export default withRouter(ReleasesManager);
