import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';

import APIs from '../api/APIs';
import { fetchWithCSRF } from '../api/APIUtils';
import SerialNumbers from '../util/SerialNumbers';

import CharInputs from '../util/CharInputs';

const styles = theme => ({
    notesField: {
        '& > .MuiInputBase-multiline': {
            height: '100%',
        },
        '& > .MuiFilledInput-root': {
            paddingTop: theme.spacing(2)
        }
    }
});

const NO_TILT_MODULE = 'None';

/**
 * Properties:
 *      handleInstallEventAdded: () => {}
 *      width: string
 *      getMainUnit: (string) => {}
 *      getVehicle: (string) => {}
 */
class InstallEventForm extends Component
{
    state = {
        licensePlate: Array(6).fill(''),
        mainUnitVer: Array(SerialNumbers.VERSION_LENGTH).fill(''),
        mainUnitId: Array(SerialNumbers.ID_LENGTH).fill(''),
        tiltModuleA: NO_TILT_MODULE,
        tiltModuleB: NO_TILT_MODULE,
        install: true,
        notes: '',

        submitEnabled: true,

        doesNotExist: false,

        alreadyInstalled: false,
        alreadyInstalledPlate: '',

        notInstalled: false,
        otherInstalled: false,

        alertVisible: false,
        alertDuration: 0,
        alertMessage: '',
    }

    constructor(props) {
        super(props);
    }

    showAlert = (duration, msg) =>
    {
        this.setState({
            alertVisible: true,
            alertDuration: duration,
            alertMsg: msg
        });
    }

    getLicensePlate = (licensePlate) =>
    {
        return licensePlate.join('');
    }

    getMainUnitSN = (mainUnitVer, mainUnitId) =>
    {
        return `${SerialNumbers.PREFIX}-${SerialNumbers.Parts.MAIN_UNIT}-${mainUnitVer.join('')}-${mainUnitId.join('')}`;
    }

    setLicensePlateCharAt = (index, char) =>
    {
        var newText = [...this.state.licensePlate];
        newText[index] = char;
        this.setState({
            licensePlate: newText
        });
    }

    setMainUnitVerCharAt = (index, char) =>
    {
        var newText = [...this.state.mainUnitVer];
        newText[index] = char;
        this.setState({
            mainUnitVer: newText
        });

        var mainUnitSN = this.getMainUnitSN(newText, this.state.mainUnitId);
        this.handleMainUnitSNChange(mainUnitSN);
    }

    setMainUnitIdCharAt = (index, char) =>
    {
        var newText = [...this.state.mainUnitId];
        newText[index] = char;
        this.setState({
            mainUnitId: newText
        });

        var mainUnitSN = this.getMainUnitSN(this.state.mainUnitVer, newText);
        this.handleMainUnitSNChange(mainUnitSN);
    }

    handleMainUnitSNChange = (mainUnitSN) =>
    {
        if (mainUnitSN.length == SerialNumbers.TOTAL_LENGTH)
        {
            var mainUnit = this.props.getMainUnit(mainUnitSN);
            if (mainUnit)
            {
                this.setState({
                    tiltModuleA: mainUnit.tilt_module_a ? mainUnit.tilt_module_a.serial_number : NO_TILT_MODULE,
                    tiltModuleB: mainUnit.tilt_module_b ? mainUnit.tilt_module_b.serial_number : NO_TILT_MODULE,
                });
            }
        }
        else
        {
            this.setState({
                tiltModuleA: NO_TILT_MODULE,
                tiltModuleB: NO_TILT_MODULE,
            });
        }
    }

    handleInstallChange = (event) =>
    {
        this.setState({
            install: event.target.value === 'true',
        });
    }

    handleNotesChange = (event) =>
    {
        this.setState({
            notes: event.target.value
        });
    }

    validateData = (mainUnitSN, licensePlate, install) =>
    {
        var mainUnit = this.props.getMainUnit(mainUnitSN);
        // Does not exist
        if (mainUnit === null || mainUnit === undefined)
        {
            this.setState({
                doesNotExist: true,
            });
            return false;
        }

        var vehicle = this.props.getVehicle(mainUnitSN);

        // Install and already installed
        if (install && vehicle)
        {
            this.setState({
                alreadyInstalled: true,
                alreadyInstalledPlate: vehicle.license_plate,
            });
            return false;
        }
        
        // Uninstall ...
        if (!install)
        {
            // var licensePlate = this.getLicensePlate(this.state.licensePlate);

            // ... and not installed
            if (vehicle === null || vehicle === undefined)
            {
                this.setState({
                    notInstalled: true
                });
                return false;
            }
            // ... and installed in another vehicle
            else if (vehicle.license_plate !== licensePlate)
            {
                this.setState({
                    otherInstalled: true,
                    alreadyInstalledPlate: vehicle.license_plate,
                });
                return false;
            }
        }

        return true;
    }

    saveInstallEvent = async (installEvent) =>
    {
        var headers = {
            'Content-Type': 'application/json'
        };

        var resp = await fetchWithCSRF(APIs.DEVICES.INSTALL_EVENTS, { method: 'POST', headers: headers, body: JSON.stringify(installEvent)});

        if (resp.status == 201) // HTTP 201 Created (for new parkade)
        {
            var respJson = await resp.json();
            this.showAlert(1000, 'Install Event Added!');
            this.props.handleInstallEventAdded(respJson);
        }
        else
        {
            this.showAlert(5000, 'ERROR: Unable to save install event!');
        }
    }

    handleSubmit = async (event) =>
    {
        event.preventDefault();

        var mainUnitSN = this.getMainUnitSN(this.state.mainUnitVer, this.state.mainUnitId);
        var licensePlate = this.getLicensePlate(this.state.licensePlate);
        
        if (this.validateData(mainUnitSN, licensePlate, this.state.install))
        {
            var installEvent = {
                main_unit: this.state.mainUnitId.join(''),
                license_plate: licensePlate,
                timestamp: new Date().toISOString(),
                install: this.state.install,
                notes: this.state.notes,
            };

            this.setState({
                submitEnabled: false,
            });

            await this.saveInstallEvent(installEvent);

            this.setState({
                submitEnabled: true,
            });
        }
    }

    render()
    {
        const { classes } = this.props;

        const currentDevice = (<Typography color='secondary' component={'span'}>{this.getMainUnitSN(this.state.mainUnitVer, this.state.mainUnitId)}</Typography>);
        const alreadyInstalledPlate = (<Typography color='secondary' component={'span'}>{this.state.alreadyInstalledPlate}</Typography>)
        const currentPlate = (<Typography color='secondary' component={'span'}>{this.getLicensePlate(this.state.licensePlate)}</Typography>)

        return (
            <Paper style={{display: 'flex', flexDirection: 'column', width: this.props.width}} component='form' onSubmit={this.handleSubmit}>
                <Box m={2}><Typography variant='h6'>Add Install Event</Typography></Box>

                <Divider/>

                <Box display='flex' flexDirection='column' p={2} mb={1}>
                    <Box mb={1}><Typography variant='overline'><b>License Plate</b></Typography></Box>
                    <Box mx='auto' mb={1}>
                        <CharInputs
                            numInputs={6}
                            inputRegExp={/^[A-Z0-9]*$/}
                            text={this.state.licensePlate}
                            setCharAt={this.setLicensePlateCharAt}
                            fontSize='xx-large'
                            charBoxWidth='32px' />
                    </Box>
                </Box>

                <Divider/>

                <Box display='flex' flexDirection='column' p={2} mb={1}>
                    <Box mb={1}><Typography variant='overline'><b>Devices</b></Typography></Box>
                    <Box mx='auto' mb={2} display='flex' flexDirection='row'>
                        <Typography variant='h6'>{SerialNumbers.PREFIX} &ndash; {SerialNumbers.Parts.MAIN_UNIT} &ndash; </Typography>
                        <CharInputs
                            numInputs={2}
                            inputRegExp={/^[0-9]*$/}
                            text={this.state.mainUnitVer}
                            setCharAt={this.setMainUnitVerCharAt}
                            fontSize='x-large'
                            charBoxWidth='24px' />
                        <Typography variant='h6'>&ndash;</Typography>
                        <CharInputs
                            numInputs={5}
                            inputRegExp={/^[0-9]*$/}
                            text={this.state.mainUnitId}
                            setCharAt={this.setMainUnitIdCharAt}
                            fontSize='x-large'
                            charBoxWidth='24px' />
                    </Box>
                    <Typography variant='button' style={{textAlign: 'center'}}><Typography variant='caption' style={{marginRight: '8px'}}>Tilt Module A: </Typography>{this.state.tiltModuleA}</Typography>
                    <Typography variant='button' style={{textAlign: 'center'}}><Typography variant='caption' style={{marginRight: '8px'}}>Tilt Module B: </Typography>{this.state.tiltModuleB}</Typography>
                </Box>

                <Divider/>

                <Box display='flex' flexDirection='column' p={2} mb={0}>
                    <Box mx='auto'>
                        <FormControl component="fieldset">
                            <RadioGroup name="install" value={this.state.install.toString()} onChange={this.handleInstallChange} row={true}>
                                <FormControlLabel value="true" control={<Radio color='primary'/>} label="Install" />
                                <FormControlLabel value="false" control={<Radio color='secondary'/>} label="Uninstall" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>

                <Divider/>

                <Box flex={1} display='flex' flexDirection='column' p={2} mb={1} minHeight={0}>
                    <Box mb={1}><Typography variant='overline'><b>Notes</b></Typography></Box>
                    <TextField variant='filled' style={{flex: 1, overflow: 'auto'}}
                            className={classes.notesField}
                            placeholder='Optional'
                            label=''
                            size='small'
                            name='notes'
                            multiline
                            inputProps={{
                                style: {height: '100%', overflow: 'auto'}
                            }}
                            onChange={this.handleNotesChange}
                            value={this.state.notes}/>
                </Box>

                <Divider/>

                <Box display='flex' flexDirection='column' p={2} mb={1}>
                    <Button variant='contained' type='submit' color='primary' disabled={!this.state.submitEnabled}>Submit</Button>
                </Box>

                <Dialog open={this.state.doesNotExist}>
                    <DialogTitle>Device Does Not Exist</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The device {currentDevice} does not exist.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => {this.setState({doesNotExist: false})}} color='primary'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.alreadyInstalled}>
                    <DialogTitle>Device Already Installed</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The device {currentDevice} is already installed into vehicle {alreadyInstalledPlate}.
                            <br/><br/>
                            Please add an <b>Uninstall</b> event for this device before installing into another vehicle.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => {this.setState({alreadyInstalled: false})}} color='primary'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.notInstalled}>
                    <DialogTitle>Device Not Installed</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The device {currentDevice} is NOT currently installed into a vehicle.
                            <br/><br/>
                            Please add an <b>Install</b> event for this device before uninstalling from a vehicle.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => {this.setState({notInstalled: false})}} color='primary'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.otherInstalled}>
                    <DialogTitle>Device Installed in Another Vehicle</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The device {currentDevice} is currently installed into {alreadyInstalledPlate}, NOT {currentPlate}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => {this.setState({otherInstalled: false})}} color='primary'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={this.state.alertVisible}
                    autoHideDuration={this.state.alertDuration}
                    message={this.state.alertMsg}
                    onClose={() => {this.setState({alertVisible: false})}}>
                </Snackbar>

            </Paper>
        )
    }
}

export default withStyles(styles, {withTheme: true})(InstallEventForm);