import React, { Component } from 'react';

import Box from '@material-ui/core/Box';

import APIs from '../api/APIs';

import BreadcrumbAppBar from '../util/BreadcrumbAppBar';
import DevicesLists from './DevicesLists';
import DevicesAddForm from './DevicesAddForm';

class DevicesManager extends Component
{
    state = {
        installedVehicles: [],
        mainUnits: [],
        tiltModules: [],
        installEvents: [],
    }

    refreshMainUnits = async () =>
    {
        var resp = await fetch(APIs.DEVICES.MAIN_UNITS);
        if (resp.status === 200)
        {
            var mainUnits = await resp.json();
            this.setState({
                mainUnits: mainUnits,
            });
        }
    }

    refreshTiltModules = async () =>
    {
        var resp = await fetch(APIs.DEVICES.TILT_MODULES);
        if (resp.status === 200)
        {
            var tiltModules = await resp.json();
            this.setState({
                tiltModules: tiltModules,
            });
        }
    }

    refreshInstallEvents = async () =>
    {
        var resp = await fetch(APIs.DEVICES.INSTALL_EVENTS);
        if (resp.status === 200)
        {
            var installEvents = await resp.json();
            this.setState({
                installEvents: installEvents,
            });
        }
    }

    handleNewMainUnits = async () =>
    {
        await this.refreshMainUnits();
    }

    handleNewTiltModules = async () =>
    {
        await this.refreshTiltModules();
    }

    async componentDidMount()
    {
        await this.refreshMainUnits();
        await this.refreshTiltModules();
        await this.refreshInstallEvents();
    }

    render()
    {
        document.title = 'Rothar - Devices';

        return (
            <Box display='flex' flexDirection='column' minHeight={0} width={1}>
                <BreadcrumbAppBar/>

                <Box display='flex' flexDirection='row' width={1} height={1} pt={8}>

                    <DevicesLists flex={1}
                        mainUnits={this.state.mainUnits}
                        tiltModules={this.state.tiltModules} />

                    <DevicesAddForm width='300px'
                        handleNewMainUnits={this.handleNewMainUnits}
                        handleNewTiltModules={this.handleNewTiltModules} />

                </Box>
            </Box>
        )
    }
}

export default DevicesManager;
