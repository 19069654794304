import React, { Component } from 'react';

import Box from '@material-ui/core/Box';

import APIs from '../api/APIs';

import BreadcrumbAppBar from '../util/BreadcrumbAppBar';
import LogsLists from './LogsLists';

class LogsManager extends Component
{
    state = {
        logUploads: [],
    }

    refreshLogUploads = async () =>
    {
        var resp = await fetch(APIs.DEVICES.LOG_UPLOADS);
        if (resp.status === 200)
        {
            var logUploads = await resp.json();
            this.setState({
                logUploads: logUploads,
            });
        }
    }

    async componentDidMount()
    {
        await this.refreshLogUploads();
    }

    render()
    {
        document.title = 'Rothar - Logs';

        return (
            <Box display='flex' flexDirection='column' minHeight={0} width={1}>
                <BreadcrumbAppBar/>

                <Box display='flex' flexDirection='row' width={1} height={1} pt={8}>

                    <LogsLists flex={1}
                        logUploads={this.state.logUploads} />

                </Box>
            </Box>
        )
    }
}

export default LogsManager;
