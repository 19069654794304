import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { w3cwebsocket as W3CWebSocket } from "websocket";

import dateFormat from 'dateformat';

import { getParkadeProxImg } from './RotharConnectUtils';
import { QueryMessages } from './RotharConnectMsgs';

const WS_PORT = 4242;

class RotharConnect extends Component
{
    state = {
        ipAddress: '',
        connected: false,
        webSocket: null,

        command: '',

        querySys: {
            timestamp: 0,
            macAddress: null,
            serialNum: null,
            fwVersion: null,
            fwHash: null,
            pkdsVersion: null,
            pkdsHash: null,
        },
        queryState: {
            timestamp: null,
            state: null,
            bikePresent: null,
            brightness: null,
            currentProximity: null,
            gpsUnixTimestamp: null,
            gpsLat: null,
            gpsLng: null,
            gpsHeading: null,
            gpsSpeed: null,
        },
        queryTilt: {
            timestamp: null,
            tilt0Addr: null,
            tilt0Bat_mV: null,
            tilt0Bat_pcnt: null,
            tilt0Temp: null,
            tilt1Addr: null,
            tilt1Bat_mV: null,
            tilt1Bat_pcnt: null,
            tilt1Temp: null,
        },
    }

    handleWSConnected = () =>
    {
        this.setState({
            connected: true
        });

        // Initial message to get system info
        this.state.webSocket.send(QueryMessages.SYS);

        if (this.props.handleWSConnected) this.props.handleWSConnected();
    }

    handleWSMessage = (message) =>
    {
        var data = message.data;

        var fields = data.split(",");
        var msgType = fields.shift() // Pop first item of array

        var dataObj = null;

        switch (msgType)
        {
            case QueryMessages.SYS:
                dataObj = {
                    timestamp:   parseInt(fields[0]),
                    macAddress:  fields[1],
                    serialNum:   fields[2],
                    fwVersion:   fields[3],
                    fwHash:      fields[4],
                    pkdsVersion: fields[5],
                    pkdsHash:    fields[6],
                };
                this.setState({ querySys: dataObj });
                break;

            case QueryMessages.STATE:
                var unixTS = parseInt(fields[5]);
                if (unixTS === 0)
                {
                    unixTS = null;
                }

                dataObj = {
                    timestamp:        parseInt(fields[0]),
                    state:            parseInt(fields[1]),
                    bikePresent:      parseInt(fields[2]),
                    brightness:       parseInt(fields[3]),
                    currentProximity: parseInt(fields[4]),
                    gpsUnixTimestamp: unixTS,
                    gpsLat:           parseFloat(fields[6]),
                    gpsLng:           parseFloat(fields[7]),
                    gpsHeading:       parseFloat(fields[8]),
                    gpsSpeed:         parseFloat(fields[9]),
                };
                this.setState({ queryState: dataObj });
                break;

            case QueryMessages.TILT:
                dataObj = {
                    timestamp:     parseInt(fields[0]),
                    tilt0Addr:     fields[1],
                    tilt0Bat_mV:   parseInt(fields[2]),
                    tilt0Bat_pcnt: parseInt(fields[3]),
                    tilt0Temp:     parseInt(fields[4]),
                    tilt1Addr:     fields[5],
                    tilt1Bat_mV:   parseInt(fields[6]),
                    tilt1Bat_pcnt: parseInt(fields[7]),
                    tilt1Temp:     parseInt(fields[8]),
                };
                this.setState({ queryTilt: dataObj });
                break;

            default:
                return;
        }

        this.props.onQueryResponse(msgType, dataObj);
    }

    handleWSError = (error) =>
    {
        // TODO
        if (this.props.handleWSError) this.props.handleWSError();
    }

    handleWSClose = () =>
    {
        this.setState({
            webSocket: null,
            connected: false
        });
        if (this.props.handleWSClose) this.props.handleWSClose();
    }

    handleInputChange = (event) =>
    {
        var target = event.target;
        switch(target.name)
        {
            case 'ipAddress':
                this.setState({ipAddress: target.value});
                break;
            case 'command':
                this.setState({command: target.value});
                break;
            default:
                break;
        }
    }

    handleConnect = (event) =>
    {
        // Stop page reload
        event.preventDefault();

        var webSocket = new W3CWebSocket(`ws://${this.state.ipAddress}:${WS_PORT}/ctrl`); 
        webSocket.onopen = this.handleWSConnected;
        webSocket.onmessage = this.handleWSMessage;
        webSocket.onerror = this.handleWSError;
        webSocket.onclose = this.handleWSClose;

        this.setState({
            webSocket: webSocket
        });
    }

    handleDisconnect = (event) =>
    {
        event?.preventDefault();

        this.state.webSocket?.close(1000);
        this.setState({
            webSocket: null,
            connected: false
        });
    }

    handleSendCmd = (event) =>
    {
        event?.preventDefault();

        if (this.state.connected)
        {
            this.state.webSocket?.send(`/${this.state.command}`);
            this.setState({
                command: ''
            });
        }
    }

    componentWillUnmount()
    {
        this.handleDisconnect(null);
    }

    render()
    {
        var childrenWithWS = null;
        if (this.state.connected)
        {
            childrenWithWS = React.Children.map(this.props.children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { webSocket: this.state.webSocket });
                }
                return child;
            });
        }

        var EMPTY_VALUE = (<>- - -</>);

        var dataLabel = (text) => (<TableCell component='th'><b>{text}</b></TableCell>);

        return (
            <Box
                display='flex'
                flexDirection='column'
                minWidth='600px'
                width={1}
                height={1}>

                <AppBar position="static" style={{background: this.state.connected ? '#50c878' : '#ff5b4f'}}>
                    <Toolbar>
                        <Typography variant="h6" style={{flexGrow: 1}}>
                        Rothar Connect
                        </Typography>
                    </Toolbar>
                </AppBar>

                <form
                    style={{width: '100%'}}
                    onSubmit={this.state.connected ? this.handleDisconnect : this.handleConnect}>

                    <Box mx={3} my={2} display='flex'>
                        <Box flexGrow={1}>
                            <TextField
                                label='IP Address'
                                size='small'
                                fullWidth
                                error={false}
                                disabled={this.state.connected}
                                name='ipAddress'
                                value={this.state.ipAddress}
                                onChange={this.handleInputChange}/>
                        </Box>
                        <Box mt={1} ml={2}>
                            <Button
                                variant='contained'
                                type='submit'
                                color='primary'>
                                {this.state.connected ? 'Disconnect' : 'Connect'}
                            </Button>
                        </Box>
                    </Box>
                </form>

                <Box mx={3} my={2} display='flex' flexDirection='row'>
                    <TableContainer component={Paper} elevation={4}>
                        <Table size='small'>
                            <TableBody>
                                <TableRow>
                                    {dataLabel('MAC Addr')}
                                    <TableCell>{this.state.querySys.macAddress ?? EMPTY_VALUE}</TableCell>
                                </TableRow>
                                <TableRow>
                                    {dataLabel('S/N')}
                                    <TableCell>{this.state.querySys.serialNum ?? EMPTY_VALUE}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mx={1}></Box>
                    <TableContainer component={Paper} elevation={4}>
                        <Table size='small'>
                            <TableBody>
                                <TableRow>
                                    {dataLabel('FW')}
                                    <TableCell>{this.state.querySys.fwVersion ? `${this.state.querySys.fwVersion} (${this.state.querySys.fwHash})` : EMPTY_VALUE}</TableCell>
                                </TableRow>
                                <TableRow>
                                    {dataLabel('PKDS')}
                                    <TableCell>{this.state.querySys.pkdsVersion ? `${this.state.querySys.pkdsVersion} (${this.state.querySys.pkdsHash})` : EMPTY_VALUE}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box
                    mx='auto'
                    my={2}
                    boxShadow={15}
                    zIndex={100}
                    width='400px'>
                    <img alt='Device State' src={getParkadeProxImg(this.state.queryState.currentProximity, this.state.queryState.bikePresent)} width='100%' />
                </Box>

                <Box mx={3} my={2} display='flex' flexDirection='row' alignItems='center'>
                    <Box width={1}>
                        <TableContainer component={Paper} elevation={4}>
                            <Table size='small'>
                                <TableBody>
                                    <TableRow>
                                        {dataLabel('Brightness')}
                                        <TableCell>{(this.state.queryState.brightness != null) ? `${Math.round((this.state.queryState.brightness * 100) / 255)} %` : EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Date')}
                                        <TableCell>{this.state.queryState.gpsUnixTimestamp ? dateFormat(this.state.queryState.gpsUnixTimestamp * 1000, 'yyyy-mm-dd') : EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Time')}
                                        <TableCell>{this.state.queryState.gpsUnixTimestamp ? dateFormat(this.state.queryState.gpsUnixTimestamp * 1000, 'h:MM:ss TT') : EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Latitude')}
                                        <TableCell>{this.state.queryState.gpsLat?.toFixed(6) ?? EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Longitude')}
                                        <TableCell>{this.state.queryState.gpsLng?.toFixed(6) ?? EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Heading')}
                                        <TableCell>{this.state.queryState.gpsHeading?.toFixed(2) ?? EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Speed')}
                                        <TableCell>{this.state.queryState.gpsSpeed?.toFixed(2) ?? EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box mx={1}></Box>
                    <Box display='flex' width={1} flexDirection='column'>
                        <TableContainer component={Paper} elevation={4}>
                            <Table size='small'>
                                <TableBody>
                                    <TableRow>
                                        {dataLabel('Tilt 0 Addr')}
                                        <TableCell>{this.state.queryTilt.tilt0Addr ?? EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Tilt 0 Batt')}
                                        <TableCell>{this.state.queryTilt.tilt0Bat_mV ? `${this.state.queryTilt.tilt0Bat_mV} mV (${this.state.queryTilt.tilt0Bat_pcnt} %)` : EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Tilt 0 Temp')}
                                        <TableCell>{this.state.queryTilt.tilt0Temp ? `${this.state.queryTilt.tilt0Temp} ${String.fromCharCode(176)}C` : EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box my={1}></Box>
                        <TableContainer component={Paper} elevation={4}>
                            <Table size='small'>
                                <TableBody>
                                    <TableRow>
                                        {dataLabel('Tilt 1 Addr')}
                                        <TableCell>{this.state.queryTilt.tilt1Addr ?? EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Tilt 1 Batt')}
                                        <TableCell>{this.state.queryTilt.tilt1Bat_mV ? `${this.state.queryTilt.tilt1Bat_mV} mV (${this.state.queryTilt.tilt1Bat_pcnt} %)` : EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {dataLabel('Tilt 1 Temp')}
                                        <TableCell>{this.state.queryTilt.tilt1Temp ? `${this.state.queryTilt.tilt1Temp} ${String.fromCharCode(176)}C` : EMPTY_VALUE}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>

                <form
                    style={{width: '100%'}}
                    onSubmit={this.handleSendCmd}>

                    <Box mx={3} my={2} display='flex'>
                        <Box flexGrow={1}>
                            <TextField
                                label='Command'
                                size='small'
                                fullWidth
                                error={false}
                                disabled={!this.state.connected}
                                name='command'
                                value={this.state.command}
                                onChange={this.handleInputChange}/>
                        </Box>
                        <Box mt={1} ml={2}>
                            <Button
                                variant='contained'
                                type='submit'
                                color='primary'
                                disabled={!this.state.connected}>
                                Send Command
                            </Button>
                        </Box>
                    </Box>
                </form>

                {childrenWithWS}

            </Box>
        );
    }
}

export default RotharConnect;
