var SerialNumbers =
{
    PREFIX: 'RTHR',
    Parts: {
        MAIN_UNIT: 'MU',
        TILT_MODULE: 'TM',
    },
    VERSION_LENGTH: 2,
    ID_LENGTH: 5,
    TOTAL_LENGTH: 16
};

export default SerialNumbers;