import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';

import APIs from '../api/APIs';
import { fetchWithCSRF } from '../api/APIUtils';

/**
 * Properties:
 *      width: string
 *      handleReleaseCreated: () => {}
 *      checkVersionExists: (versionName) => {}
 */
class ParkadesReleaseForm extends Component
{
    state = {
        submitEnabled: true,
        validationErrors: {
            versionName: null,
        },

        alertVisible: false,
        alertDuration: 0,
        alertMsg: '',
    }

    constructor(props)
    {
        super(props);

        this.versionName = React.createRef();
        this.formatVersion = React.createRef();
    }

    showAlert = (duration, msg) =>
    {
        this.setState({
            alertVisible: true,
            alertDuration: duration,
            alertMsg: msg
        });
    }

    checkValidationErrors = (validationErrors) =>
    {
        var validationPass = true;
        for (var key in validationErrors)
        {
            if (validationErrors[key] != null)
            {
                validationPass = false;
            }
        }
        return validationPass;
    }

    createRelease = async (versionName, formatVersion) =>
    {
        var headers = {
            'Content-Type': 'application/json'
        };

        var resp = await fetchWithCSRF(APIs.RELEASES.PARKADES, { method: 'POST', headers: headers, body: JSON.stringify({
            version: versionName,
            format_version: formatVersion,
        })});

        if (resp.status === 201) // HTTP 201 Created
        {
            var respJson = await resp.json();
            this.showAlert(1000, 'Release Created!');
            this.props.handleReleaseCreated(respJson);
        }
        else
        {
            this.showAlert(5000, 'ERROR: Unable to create release!');
        }
    }

    handleSubmit = async (event) =>
    {
        event.preventDefault();

        var validationErrors = {...this.state.validationErrors};
        var versionName = this.versionName.current.value;
        var formatVersion = this.formatVersion.current.value;

        if (!(/^[0-9]+\.[0-9]+\.[0-9]+$/.test(versionName)))
        {
            validationErrors.versionName = 'Must be in the format x.y.z';
        }
        else if (this.props.checkVersionExists(versionName))
        {
            validationErrors.versionName = `Version ${versionName} already exists`;
        }
        else
        {
            validationErrors.versionName = null;
        }

        this.setState({validationErrors: validationErrors});

        if (this.checkValidationErrors(validationErrors))
        {
            this.setState({
                submitEnabled: false,
            });

            await this.createRelease(versionName, formatVersion);

            this.setState({
                submitEnabled: true,
            });
        }
    }

    render()
    {
        return (
            <Paper style={{display: 'flex', flexDirection: 'column', width: this.props.width}} component='form' onSubmit={this.handleSubmit}>
                <Box m={2}><Typography variant='h6'>Create Parkades Release</Typography></Box>

                <Divider/>

                <Box mx={2} mt={2} mb={1}>
                    <TextField variant='filled'
                        inputRef={this.versionName}
                        label='Version Name (e.g. 1.2.3)'
                        required
                        size='small'
                        error={this.state.validationErrors.versionName != null}
                        helperText={this.state.validationErrors.versionName}
                        name='versionName'
                        fullWidth/>
                </Box>

                <Box mx={2} my={1} display='none'>
                    <TextField variant='filled'
                        inputRef={this.formatVersion}
                        label='Format Version'
                        select
                        size='small'
                        fullWidth
                        required
                        name='formatVersion'
                        defaultValue={1}>
                            <MenuItem key={1} value={1}>1</MenuItem>
                    </TextField>
                </Box>

                <Box display='flex' flexDirection='column' mx={2} my={1}>
                    <Button variant='contained' type='submit' color='primary' disabled={!this.state.submitEnabled}>Create Release</Button>
                </Box>

                <Snackbar
                    open={this.state.alertVisible}
                    autoHideDuration={this.state.alertDuration}
                    message={this.state.alertMsg}
                    onClose={() => {this.setState({alertVisible: false})}}>
                </Snackbar>

            </Paper>
        )
    }
}

export default ParkadesReleaseForm;
