import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import APIs from '../api/APIs';
import { fetchWithCSRF } from '../api/APIUtils';

import BreadcrumbAppBar from '../util/BreadcrumbAppBar';

import ParkadeMap from '../parkade_map/ParkadeMap';
import CoordinateHistory from '../parkade_map/CoordinateHistory';

import RotharConnect from '../rothar_connect/RotharConnect';
import { QueryMessages } from '../rothar_connect/RotharConnectMsgs';
import PeriodicSend from '../rothar_connect/PeriodicSend';
import { getParkadeProxColor } from '../rothar_connect/RotharConnectUtils';

import ParkadeList from './ParkadeList';
import ParkadeDetails from './ParkadeDetails';

const DEFAULT_LAT = 49.255136;
const DEFAULT_LNG = -122.998764;

const ZOOM_DEFAULT = 12;
const ZOOM_PARKADE = 20;
const ZOOM_FOLLOW = 19;

const QUERY_STATE_INTERVAL_MS = 500;
const QUERY_TILT_INTERVAL_MS = 500;

class ParkadeManager extends Component
{
    state = {
        writeAccess: false,

        mapCenter: [DEFAULT_LAT, DEFAULT_LNG],
        mapZoom: ZOOM_DEFAULT,

        selectedId: null,
        editingParkade: null,

        editMode: false,
        addMode: false,

        alertVisible: false,
        alertDuration: 0,
        alertMsg: '',

        deleteDialogOpen: false,
        deleteParkadeName: '',
        deleteParkadeId: null,

        unsavedDialogOpen: false,
        unsavedDialogAction: null,

        ackDialogOpen: true,

        rotharConnectVisible: false,
        followVehicle: true,

        regions: [],
        parkades: [],
    }

    constructor(props)
    {
        super(props);
        this.coordinateHistory = React.createRef();
    }

    /* ********************************************************************* */
    /* ALERT / DIALOG HANDLERS                                               */
    /* ********************************************************************* */

    showAlert = (duration, msg) =>
    {
        this.setState({
            alertVisible: true,
            alertDuration: duration,
            alertMsg: msg
        });
    }

    handleAlertClose = (event) =>
    {
        this.setState({
            alertVisible: false
        });
    }

    handleDeleteDialogClose = (event) =>
    {
        this.setState({
            deleteDialogOpen: false
        });
    }

    handleUnsavedDialogClose = (event) =>
    {
        this.setState({
            unsavedDialogOpen: false,
            unsavedDialogAction: null
        });
    }

    handleUnsavedDialogConfirm = (event) =>
    {
        this.setState({
            unsavedDialogOpen: false
        });
        this.state.unsavedDialogAction();
    }

    handleAckDialogExit = async (event) =>
    {
        this.handleExit(null);
    }

    handleAckDialogContinue = (event) =>
    {
        this.setState({
            ackDialogOpen: false
        });
    }

    /* ********************************************************************* */
    /* GLOBAL EVENT HANDLERS                                                 */
    /* ********************************************************************* */

    handleGlobalKeyDown = (event) =>
    {
        switch (event.keyCode)
        {
            case 27: // ESCAPE
                if (this.state.addMode)
                {
                    this.setState({
                        addMode: false
                    });
                }
                break;
            default:
                break;
        }
    }

    /* ********************************************************************* */
    /* DATA ACCESS                                                           */
    /* ********************************************************************* */

    getParkadeById = (id) =>
    {
        return this.state.parkades.find(parkade => parkade.id === id);
    }

    refreshParkades = async (selectedId) =>
    {
        // Retrieve regions
        var regionsResp = await fetch(APIs.PARKADES.REGIONS);
        var regions = await regionsResp.json();

        // Retrieve parkades
        var parkadesResp = await fetch(APIs.PARKADES.PARKADES);
        var parkades = await parkadesResp.json();

        var newState = {};
        newState['regions'] = regions;
        newState['parkades'] = parkades.sort((a, b) => a.name.localeCompare(b.name));
        newState['editMode'] = false;

        var selectedParkade = parkades.find(parkade => parkade.id === selectedId);
        if (selectedParkade != null)
        {
            newState['mapCenter'] = [selectedParkade.center_lat, selectedParkade.center_lng];
            newState['mapZoom'] = ZOOM_PARKADE;
            newState['selectedId'] = selectedParkade.id;
            newState['editingParkade'] = this.cloneParkade(selectedParkade);
        }
        else
        {
            newState['editingParkade'] = null;
        }

        this.setState(newState);
    }

    cloneParkade = (parkade) =>
    {
        return JSON.parse(JSON.stringify(parkade));
    }

    selectParkade = (id, moveMap) =>
    {
        var selectedParkade = this.getParkadeById(id);

        var newState = {
            selectedId: id,
            editingParkade: this.cloneParkade(selectedParkade),
            editMode: false
        };

        if (moveMap)
        {
            newState['mapCenter'] = [selectedParkade.center_lat, selectedParkade.center_lng];
            newState['mapZoom'] = ZOOM_PARKADE;
        }

        this.setState(newState);
    }

    deleteParkade = async () =>
    {
        var resp = await fetchWithCSRF(APIs.PARKADES.PARKADE(this.state.deleteParkadeId), { method: 'DELETE'});
        if (resp.status === 204) // HTTP No Content
        {
            this.showAlert(1000, 'Parkade Deleted!');
        }
        else
        {
            this.showAlert(4000, 'ERROR: Unable to delete parkade!');
        }
        await this.refreshParkades(this.state.selectedId);
        this.setState({
            deleteDialogOpen: false,
            deleteParkadeName: '',
            deleteParkadeId: null
        });
    }

    /* ********************************************************************* */
    /* PARKADE MAP HANDLERS                                                  */
    /* ********************************************************************* */

    handleParkadeMapParkadeClick = (event, id) =>
    {
        if (!this.state.addMode)
        {
            event.originalEvent.view.L.DomEvent.stopPropagation(event);
        }

        if (this.state.editMode)
        {
            if (this.state.editingParkade.id !== id)
            {
                this.setState({
                    unsavedDialogOpen: true,
                    unsavedDialogAction: () => this.selectParkade(id, false)
                });
            }
        }
        else
        {
            this.selectParkade(id, false);
        }
    }

    handleParkadeMapMapClick = (event) =>
    {
        if (this.state.addMode)
        {
            // Check that a region exists
            if (this.state.regions.length <= 0)
            {
                console.log('ERROR: At least one region must exist before adding a parkade');
                this.setState({
                    addMode: false
                });
                return;
            }

            var centerLat = event.latlng.lat.toFixed(5);
            var centerLng = event.latlng.lng.toFixed(5);

            this.setState({
                mapCenter: [centerLat, centerLng],
                mapZoom: ZOOM_PARKADE,
                selectedId: null,
                editingParkade: {
                    // Don't set id field since it's a new parkade
                    evo_parking: true,
                    name: '',
                    region: this.state.regions[0].id,
                    address: '',
                    center_lat: centerLat,
                    center_lng: centerLng,
                    width: 40,
                    height: 40,
                    rotation: 0,
                    delta_heading: 0,
                    alert_heading: 0,
                    alert_heading_tolerance: 75
                },
                editMode: true,
                addMode: false
            })
        }
        else if(!this.state.editMode)
        {
            this.setState({
                selectedId: null,
                editingParkade: null
            });
        }
    }

    /* ********************************************************************* */
    /* PARKADE LIST HANDLERS                                                 */
    /* ********************************************************************* */

    handleParkadeListSelect = (event, id) =>
    {
        if (this.state.editMode)
        {
            if (this.state.editingParkade.id !== id)
            {
                this.setState({
                    unsavedDialogOpen: true,
                    unsavedDialogAction: () => this.selectParkade(id, true)
                });
            }
        }
        else
        {
            this.selectParkade(id, true);
        }
    }

    handleParkadeListAdd = (event) =>
    {
        if (this.state.editMode)
        {
            this.setState({
                unsavedDialogOpen: true,
                unsavedDialogAction: () => {
                    // Proceed without saving i.e. cancel
                    this.handleParkadeDetailsCancel(null);
                    this.enterAddMode();
                }
            });
        }
        else
        {
            this.enterAddMode();
        }
    }

    handleParkadeListDelete = (id) =>
    {
        this.setState({
            deleteDialogOpen: true,
            deleteParkadeName: this.state.parkades.find(parkade => parkade.id === id).name,
            deleteParkadeId: id
        });
    }

    enterAddMode = () =>
    {
        // Need at least one region to add a parkade
        if (this.state.regions.length > 0)
        {
            this.setState({
                addMode: true,
                editMode: false
            });
        }
        else
        {
            this.showAlert(4000, 'ERROR: At least one region must exist before adding a parkade');
        }
    }

    /* ********************************************************************* */
    /* PARKADE DETAILS HANDLERS                                              */
    /* ********************************************************************* */

    handleParkadeDetailsEdit = (event) =>
    {
        this.setState({
            editMode: true
        });
    }

    handleParkadeDetailsUpdate = (event, parkade) =>
    {
        if (!this.state.editingParkade) return;

        this.setState({
            editingParkade: parkade
        });
    }

    handleParkadeDetailsCancel = (event) =>
    {
        // Refresh the editingParkade
        this.setState({
            editingParkade: this.getParkadeById(this.state.editingParkade.id),
            editMode: false
        });
    }

    handleParkadeDetailsSave = async (id) =>
    {
        await this.refreshParkades(id);
    }
    
    /* ********************************************************************* */
    /* ROTHAR CONNECT HANDLERS                                               */
    /* ********************************************************************* */

    handleToggleRotharConnect = (event) =>
    {
        this.setState({
            rotharConnectVisible: !this.state.rotharConnectVisible
        });
    }

    handleRotharConnectQueryResponse = (msgType, dataObj) =>
    {
        if (msgType === QueryMessages.STATE)
        {
            var coordinates = [dataObj.gpsLat, dataObj.gpsLng];
            this.coordinateHistory.current?.add(coordinates, getParkadeProxColor(dataObj.currentProximity, dataObj.bikePresent));

            if (this.state.followVehicle)
            {
                this.setState({
                    mapCenter: coordinates,
                    mapZoom: ZOOM_FOLLOW,
                });
            }
        }
    }

    handleClearCoordinateHistory = () =>
    {
        this.coordinateHistory.current?.clear();
    }

    /* ********************************************************************* */
    /* OTHER HANDLERS                                                        */
    /* ********************************************************************* */

    handleFollowVehicleChanged = async (event) =>
    {
        var target = event.target;
        switch(target.name)
        {
            case 'followVehicle':
                this.setState({followVehicle: target.checked});
                break;
            default:
                break;
        }
    }

    handleExit = async (event) =>
    {
        // Check if user is currently editing
        if (this.state.editMode)
        {
            this.setState({
                unsavedDialogOpen: true,
                unsavedDialogAction: () => this.props.history.push('/')
            });
        }
        else
        {
            this.props.history.push('/');
        }
    }

    /* ********************************************************************* */
    /* REACT LIFECYCLE                                                       */
    /* ********************************************************************* */

    async componentDidMount()
    {
        // TODO handle permissions
        this.setState({writeAccess: true});

        window.addEventListener("keydown", this.handleGlobalKeyDown, false);
        await this.refreshParkades(0);
    }

    render()
    {
        document.title = 'Rothar - Parkades';

        var parkades = this.state.parkades.filter(parkade => this.state.editingParkade?.id !== parkade.id);

        var parkadeDetails;
        if (this.state.editingParkade != null)
        {
            parkades.push(this.state.editingParkade);

            parkadeDetails = (
                <ParkadeDetails
                    ref={this.parkadeDetails}
                    writeAccess={this.state.writeAccess}
                    editMode={this.state.editMode}
                    parkade={this.state.editingParkade}
                    regions={this.state.regions}
                    handleEdit={this.handleParkadeDetailsEdit}
                    handleUpdate={this.handleParkadeDetailsUpdate}
                    handleCancel={this.handleParkadeDetailsCancel}
                    handleSave={this.handleParkadeDetailsSave}
                    showAlert={this.showAlert}/>
            );
        }

        var rotharConnect = (
            <Box
                display={this.state.rotharConnectVisible ? 'flex' : 'none'}
                width='600px'
                flexDirection='column'>

                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='center'
                    m={1.5}>

                    <FormControlLabel
                        control={
                        <Switch
                            checked={this.state.followVehicle}
                            onChange={this.handleFollowVehicleChanged}
                            name="followVehicle"
                            color="primary"/>
                        }
                        label="Follow Vehicle"/>

                    <Button
                        color='secondary'
                        onClick={this.handleClearCoordinateHistory}>
                        Clear Coordinate History
                    </Button>

                </Box>

                <RotharConnect
                    onQueryResponse={this.handleRotharConnectQueryResponse}>

                    <PeriodicSend
                        key='queryState'
                        initMessage={QueryMessages.STATE}
                        messageInterval={QUERY_STATE_INTERVAL_MS} />

                    <PeriodicSend
                        key='queryTilt'
                        initMessage={QueryMessages.TILT}
                        messageInterval={QUERY_TILT_INTERVAL_MS} />

                </RotharConnect>

            </Box>
        )

        var ackDialog;
        if (this.state.writeAccess)
        {
            ackDialog = (
                <Dialog
                    open={this.state.ackDialogOpen}
                    aria-labelledby="ack-dialog-title"
                    aria-describedby="ack-dialog-description">

                    <DialogTitle id="ack-dialog-title">WARNING</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="ack-dialog-description">
                            This is a LIVE system. Please do NOT make any changes unless you have read the instructions for using this tool.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAckDialogExit} color="secondary" autoFocus>
                            Exit
                        </Button>
                        <Button onClick={this.handleAckDialogContinue} color="primary">
                            I have read the instructions
                        </Button>
                    </DialogActions>

                </Dialog>
            );
        }

        return (
            <Box
                display='flex'
                width={1}
                minHeight={1}
                flexDirection='column'>

                <BreadcrumbAppBar>
                    <Button color='inherit' onClick={this.handleToggleRotharConnect}>{this.state.rotharConnectVisible ? 'Hide Rothar Connect' : 'Show Rothar Connect'}</Button>
                </BreadcrumbAppBar>

                <Box
                    display='flex'
                    width={1}
                    height={1}
                    pt={8}
                    flexDirection='row'>

                    <Box
                        display='flex'
                        flexDirection='column'
                        width='425px'
                        height={1}
                        boxShadow={15}
                        zIndex={999}>

                        <ParkadeList
                            writeAccess={this.state.writeAccess}
                            parkades={this.state.parkades}
                            selectedId={this.state.selectedId}
                            handleParkadeAdd={this.handleParkadeListAdd}
                            handleParkadeDelete={this.handleParkadeListDelete}
                            handleParkadeSelect={this.handleParkadeListSelect} />

                        <Divider />

                        {parkadeDetails}

                    </Box>

                    <ParkadeMap
                        mapCenter={this.state.mapCenter}
                        mapZoom={this.state.mapZoom}
                        mapCursor={this.state.addMode ? 'crosshair' : null}
                        parkades={parkades}
                        selectedId={this.state.selectedId}
                        addMode={this.state.addMode}
                        handleParkadeClick={this.handleParkadeMapParkadeClick}
                        handleMapClick={this.handleParkadeMapMapClick}>

                        <CoordinateHistory ref={this.coordinateHistory}>
                        </CoordinateHistory>

                        <Backdrop
                            open={this.state.addMode}
                            style={{zIndex: 1000, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                <Box mt={5}>
                                    <Typography variant='h4'>
                                        Click on the map to place a parkade
                                    </Typography>
                                </Box>
                                <Box m={1}>
                                    <Typography variant='h6'>
                                        Press ESC to cancel
                                    </Typography>
                                </Box>
                        </Backdrop>

                    </ParkadeMap>

                    {rotharConnect}

                </Box>

                <Snackbar
                    open={this.state.alertVisible}
                    autoHideDuration={this.state.alertDuration}
                    message={this.state.alertMsg}
                    onClose={this.handleAlertClose}>
                </Snackbar>

                <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleDeleteDialogClose}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description">

                    <DialogTitle id="delete-dialog-title">Delete Parkade?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            Are you sure you want to delete the parkade: <b>{this.state.deleteParkadeName}</b>
                            <br/>
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteDialogClose} color="primary" autoFocus>
                            Cancel
                        </Button>
                        <Button onClick={this.deleteParkade} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>

                </Dialog>

                <Dialog
                    open={this.state.unsavedDialogOpen}
                    onClose={this.handleUnsavedDialogClose}
                    aria-labelledby="unsaved-dialog-title"
                    aria-describedby="unsaved-dialog-description">

                    <DialogTitle id="unsaved-dialog-title">Unsaved changes</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="unsaved-dialog-description">
                            You have unsaved changes. Are you sure you want to continue.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleUnsavedDialogClose} color="primary" autoFocus>
                            Back to Editing
                        </Button>
                        <Button onClick={this.handleUnsavedDialogConfirm} color="secondary">
                            Continue without Saving
                        </Button>
                    </DialogActions>

                </Dialog>

                {ackDialog}

            </Box>
        );
    }
}

export default withRouter(ParkadeManager);
