import { Component } from 'react';

/**
 * Properties:
 *      messageInterval: int (milliseconds)
 *      initMessage: string
 *      webSocket: W3CWebSocket
 */
class PeriodicSend extends Component
{
    constructor(props)
    {
        super(props);
        this.message = props.initMessage;
    }

    updateMessage = (message) =>
    {
        this.message = message;
    }

    sendMessage = () =>
    {
        if (this.message)
        {
            this.props.webSocket.send(this.message);
        }
    }

    startTimer = () =>
    {
        this.interval = setInterval(() => this.sendMessage(), this.props.messageInterval);
    }

    componentDidMount()
    {
        this.startTimer();
    }

    componentWillUnmount()
    {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.messageInterval !== prevProps.messageInterval)
        {
            clearInterval(this.interval);
            this.startTimer();
        }
    }

    render()
    {
        return null;
    }
}

export default PeriodicSend;