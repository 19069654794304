var APIs = {
    AUTH :
    {
        LOGIN: '/api/auth/login/',
        LOGOUT: '/api/auth/logout/',
        CSRF: '/api/auth/csrf/',
    },
    PARKADES:
    {
        REGIONS: '/api/parkades/regions/',
        PARKADES: '/api/parkades/parkades/',
        PARKADE: (id) => `/api/parkades/parkades/${id}/`,
    },
    DEVICES:
    {
        MAIN_UNITS: '/api/devices/main_units/',
        TILT_MODULES: '/api/devices/tilt_modules/',
        LOG_UPLOADS: '/api/devices/log_uploads/',
        LOG_UPLOADS_DOWNLOAD: (id) => `/api/devices/log_uploads/${id}/?download`,
        INSTALL_EVENTS: '/api/devices/install_events/',
        GEN_MAIN_UNITS: '/api/devices/gen_main_units/',
        GEN_TILT_MODULES: '/api/devices/gen_tilt_modules/',
    },
    RELEASES:
    {
        MAIN_FIRMWARE: '/api/releases/main_firmware/',
        MAIN_FIRMWARE_DOWNLOAD: (id) => `/api/releases/main_firmware/${id}/?download`,
        TILT_FIRMWARE: '/api/releases/tilt_firmware/',
        TILT_FIRMWARE_DOWNLOAD: (id) => `/api/releases/tilt_firmware/${id}/?download`,
        PARKADES: '/api/releases/parkades/',
        PARKADES_DOWNLOAD: (id) => `/api/releases/parkades/${id}/?download`
    }
};

export default APIs;