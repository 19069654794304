import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import dateFormat from 'dateformat';

import FilterDataGrid from '../util/FilterDataGrid';

import APIs from '../api/APIs';

const TIMESTAMP_FORMAT = 'yyyy-mm-dd h:MM:ss TT';

var dateFormatter = (params) => (params.value === null) ? '-' : dateFormat(params.value, TIMESTAMP_FORMAT)
var nullFormatter = (params) => (params.value === null || params.value === '') ? '-' : params.value;

const parkadesReleasesColumns = [
    { field: 'id', headerName: 'Version Number', flex: 1, valueFormatter: nullFormatter },
    { field: 'version', headerName: 'Version Name', flex: 1, valueFormatter: nullFormatter },
    { field: 'format_version', headerName: 'Format Version', flex: 1, valueFormatter: nullFormatter },
    { field: 'sha256', headerName: 'SHA-256', flex: 1, valueFormatter: nullFormatter },
    { field: 'date_created', headerName: 'Date Created', flex: 1, valueFormatter: dateFormatter },
    {
        field: '', headerName: '', width: 125, filterable: false, disableReorder: true, disableExport: true, disableColumnMenu: true, hideSortIcons: true,
        renderCell: (params) => {
            var clickHandler = (event) => {
                window.location.href = APIs.RELEASES.PARKADES_DOWNLOAD(params.row.id);
            }
            return (
                <Button variant='text' color='primary' onClick={clickHandler} fullWidth>Download</Button>
            );
        },
    },
];

const mainFirmwareReleasesColumns = [
    { field: 'id', headerName: 'Version Number', flex: 1, valueFormatter: nullFormatter },
    { field: 'version', headerName: 'Version Name', flex: 1, valueFormatter: nullFormatter },
    { field: 'githash', headerName: 'Git Hash', flex: 1, valueFormatter: nullFormatter },
    { field: 'date_created', headerName: 'Date Created', flex: 1, valueFormatter: dateFormatter },
    { field: 'status', headerName: 'Status', flex: 1, valueFormatter: nullFormatter },
    {
        field: '', headerName: '', width: 125, filterable: false, disableReorder: true, disableExport: true, disableColumnMenu: true, hideSortIcons: true,
        renderCell: (params) => {
            if (params.row.status == 'PUBLISHED')
            {
                var clickHandler = (event) => {
                    window.location.href = APIs.RELEASES.MAIN_FIRMWARE_DOWNLOAD(params.row.id);
                }
                return (
                    <Button variant='text' color='primary' onClick={clickHandler} fullWidth>Download</Button>
                );
            }
        },
    },
]

const tiltFirmwareReleasesColumns = [
    { field: 'id', headerName: 'Version Number', flex: 1, valueFormatter: nullFormatter },
    { field: 'version', headerName: 'Version Name', flex: 1, valueFormatter: nullFormatter },
    { field: 'githash', headerName: 'Git Hash', flex: 1, valueFormatter: nullFormatter },
    { field: 'date_created', headerName: 'Date Created', flex: 1, valueFormatter: dateFormatter },
    { field: 'status', headerName: 'Status', flex: 1, valueFormatter: nullFormatter },
    {
        field: '', headerName: '', width: 125, filterable: false, disableReorder: true, disableExport: true, disableColumnMenu: true, hideSortIcons: true,
        renderCell: (params) => {
            if (params.row.status == 'PUBLISHED')
            {
                var clickHandler = (event) => {
                    window.location.href = APIs.RELEASES.TILT_FIRMWARE_DOWNLOAD(params.row.id);
                }
                return (
                    <Button variant='text' color='primary' onClick={clickHandler} fullWidth>Download</Button>
                );
            }
        },
    },
]

class ReleasesLists extends Component
{
    render()
    {
        const path = this.props.match.path;

        return (
            <Box display='flex' flexDirection='column' flex={this.props.flex}>
                <Box ml={2} mr={2} mt={2}>
                    <Paper>
                        <Tabs
                            value={this.props.location.pathname}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='fullWidth'>

                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/parkades`} value={`${this.props.match.url}/parkades`} label='Parkades' />
                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/main_firmware`} value={`${this.props.match.url}/main_firmware`} label='Main Firmware' />
                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/tilt_firmware`} value={`${this.props.match.url}/tilt_firmware`} label='Tilt Firmware' />

                        </Tabs>
                    </Paper>
                </Box>

                <Box flex={1} m={2}>
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${path}/parkades`}/>
                        </Route>
                        <Route path={`${path}/parkades`}>
                            <FilterDataGrid key='Parkades' title='Rothar - Releases - Parkades' data={this.props.parkadesReleases} columns={parkadesReleasesColumns}/>
                        </Route>
                        <Route path={`${path}/main_firmware`}>
                            <FilterDataGrid key='Main Firmware' title='Rothar - Releases - Main Firmware' data={this.props.mainFirmwareReleases} columns={mainFirmwareReleasesColumns}/>
                        </Route>
                        <Route path={`${path}/tilt_firmware`}>
                            <FilterDataGrid key='Tilt Firmware' title='Rothar - Releases - Tilt Firmware' data={this.props.tiltFirmwareReleases} columns={tiltFirmwareReleasesColumns}/>
                        </Route>
                    </Switch>
                </Box>
            </Box>
        )
    }
}

export default withRouter(ReleasesLists);
