import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import dateFormat from 'dateformat';

import FilterDataGrid from '../util/FilterDataGrid';

import APIs from '../api/APIs';

const TIMESTAMP_FORMAT = 'yyyy-mm-dd h:MM:ss TT';

var dateFormatter = (params) => (params.value === null) ? '-' : dateFormat(params.value, TIMESTAMP_FORMAT)
var nullFormatter = (params) => (params.value === null || params.value === '') ? '-' : params.value;

const logUploadColumns = [
    { field: 'id', headerName: 'ID', flex: 0, valueFormatter: nullFormatter },
    { field: 'main_unit_sn', headerName: 'Main Unit S/N', flex: 1, valueFormatter: nullFormatter },
    { field: 'date_created', headerName: 'Date Uploaded', flex: 1, valueFormatter: dateFormatter },
    {
        field: '', headerName: '', width: 125, filterable: false, disableReorder: true, disableExport: true, disableColumnMenu: true, hideSortIcons: true,
        renderCell: (params) => {
            var clickHandler = (event) => {
                window.location.href = APIs.DEVICES.LOG_UPLOADS_DOWNLOAD(params.row.id);
            }
            return (
                <Button variant='text' color='primary' onClick={clickHandler} fullWidth>Download</Button>
            );
        },
    },
];

class LogsLists extends Component
{
    render()
    {
        const path = this.props.match.path;

        return (
            <Box display='flex' flexDirection='column' flex={this.props.flex}>
                <Box ml={2} mr={2} mt={2}>
                    <Paper>
                        <Tabs
                            value={this.props.location.pathname}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='fullWidth'>

                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/log_uploads`} value={`${this.props.match.url}/log_uploads`} label='Log Uploads' />

                        </Tabs>
                    </Paper>
                </Box>

                <Box flex={1} m={2}>
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${path}/log_uploads`}/>
                        </Route>
                        <Route path={`${path}/log_uploads`}>
                            <FilterDataGrid key='Log Uploads' title='Rothar - Logs - Log Uploads' data={this.props.logUploads} columns={logUploadColumns}/>
                        </Route>
                    </Switch>
                </Box>
            </Box>
        )
    }
}

export default withRouter(LogsLists);
