import imgStateSafeNoBike from './img/State_SAFE_NO_BIKE.png';
import imgStateSafeBike from './img/State_SAFE_BIKE.png';
import imgStateDanger from './img/State_DANGER.png';
import imgStateAlert from './img/State_ALERT.png';

const PARKADE_PROXIMITY = {
    PROX_NONE   : 0,
    PROX_DANGER : 1,
    PROX_ALERT  : 2
};

var getParkadeProxImg = (currentProximity, bikePresent) =>
{
    if (!bikePresent) return imgStateSafeNoBike;

    switch (currentProximity)
    {
        case PARKADE_PROXIMITY.PROX_NONE:
            return imgStateSafeBike;
        case PARKADE_PROXIMITY.PROX_DANGER:
            return imgStateDanger;
        case PARKADE_PROXIMITY.PROX_ALERT:
            return imgStateAlert;
        default:
            return imgStateSafeNoBike;
    }
}

var getParkadeProxColor = (currentProximity, bikePresent) =>
{
    if (!bikePresent) return 'black';

    switch (currentProximity)
    {
        case PARKADE_PROXIMITY.PROX_NONE:
            return 'green';
        case PARKADE_PROXIMITY.PROX_DANGER:
            return 'orange';
        case PARKADE_PROXIMITY.PROX_ALERT:
            return 'red';
        default:
            return 'grey';
    }
}

export { getParkadeProxColor, getParkadeProxImg };