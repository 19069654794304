import React, { Component } from 'react';

import { LayerGroup } from 'react-leaflet';
import L from 'leaflet';

class CoordinateHistory extends Component
{
    constructor(props)
    {
        super(props);
        this.layerGroup = React.createRef();
    }

    add = (coords, color) =>
    {
        // Ignore null island
        // TODO: proper zero check
        if ((Math.abs(coords[0]) <= 1e-8) && (Math.abs(coords[1]) <= 1e-8))
        {
            return;
        }

        var newMarker = L.circle(coords, {
            radius: 1,
            color: color,
            fillColor: color,
            fillOpacity: 1
        });
        this.layerGroup.current?.leafletElement?.addLayer(newMarker);
    }

    clear = () =>
    {
        this.layerGroup.current?.leafletElement?.clearLayers();
    }

    render()
    {
        return (
            <LayerGroup ref={this.layerGroup}>
            </LayerGroup>
        );
    }
}

export default CoordinateHistory;