import React, { Component } from 'react';

import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';

// Based on: https://github.com/dannyradden/single-character-input-boxes

/**
 * Properties:
 *      innerRef: React.createRef
 *      index: int
 *      value: string
 *      onChange: (event) => {}
 *      onKeyDown: (event) => {}
 *      fontSize: string
 *      charBoxWidth: string
 */
class CharInput extends Component
{
    handleFocus = (event) =>
    {
    }

    render()
    {
        return (
            <TextField variant='filled' style={{width: this.props.charBoxWidth, marginLeft: '4px', marginRight: '4px'}}
                inputRef={this.props.innerRef}
                label=''
                size='small'
                name='mainUnits.num'
                value={this.props.value}
                onChange={this.props.onChange}
                onKeyDown={this.props.onKeyDown}
                onFocus={this.handleFocus}
                inputProps={{
                    index: this.props.index,
                    maxLength: 1,
                    style: { display: 'inline', textAlign: 'center', fontSize: this.props.fontSize, padding: '0px' }
                }}
                required/>
        )
    }
}

const CharInputWithRef = React.forwardRef((props, ref) => (
    <CharInput {...props} innerRef={ref} />
));

/**
 * Properties:
 *      numInputs: int
 *      inputRegExp: RegExp
 *      setCharAt: (index, char) => {}
 *      text: string
 *      fontSize: string
 *      charBoxWidth: string
 */
class CharInputs extends Component
{
    state = {
    }
    
    constructor(props) {
        super(props);
        this.inputRefs = []
        for(var i = 0; i < props.numInputs; i++)
        {
            this.inputRefs.push(React.createRef);
        }
    }

    focusPrev = (index) =>
    {
        if (index > 0) this.inputRefs[index - 1].focus();
    }

    focusNext = (index) =>
    {
        if (index < (this.props.numInputs - 1)) this.inputRefs[index + 1].focus();
    }

    handleKeyDown = (event) =>
    {
        var index = parseInt(event.target.getAttribute('index'));
        if (event.key === 'Backspace')
        {
            if (event.target.value === '' && index > 0)
            {
                // This box is empty and a previous box exists
                this.props.setCharAt(index - 1, '');
                this.inputRefs[index - 1].focus();
            }
            else
            {
                // This box is NOT empty
                this.props.setCharAt(index, '');
            }
        }
        else if (event.key === 'ArrowLeft')
        {
            this.focusPrev(index);
        }
        else if (event.key === 'ArrowRight')
        {
            this.focusNext(index);
        }
    }

    handleInputChange = (event) =>
    {
        var index = parseInt(event.target.getAttribute('index'));
        var value = event.target.value;
        value = value.toUpperCase();
        if (value.match(this.props.inputRegExp))
        {
            this.props.setCharAt(index, value);
            this.focusNext(index);
        }
        else
        {
            event.preventDefault();
        }
    }

    render()
    {
        return (
            <Box display='flex' flexDirection='row'>
                {[...Array(this.props.numInputs)].map((x, i) =>
                    <CharInputWithRef
                        ref={ref => this.inputRefs[i] = ref}
                        key={i}
                        index={i}
                        value={this.props.text[i]}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        fontSize={this.props.fontSize}
                        charBoxWidth={this.props.charBoxWidth} />
                )}
            </Box>
        )
    }
}

export default CharInputs