import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import queryString from 'query-string'

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import evoLogo from './img/EvoLogo.png';

import { getLoggedInUser, login } from './auth/AuthUtils';

class Login extends Component
{
    state = {
        username: '',
        password: '',
        incorrectCreds: false,
        loginChecked: false,
    }

    handleInputChange = (event) =>
    {
        switch(event.target.name)
        {
            case 'username':
                this.setState({username: event.target.value, incorrectCreds: false});
                break;
            case 'password':
                this.setState({password: event.target.value, incorrectCreds: false});
                break;
            default:
                break;
        }
    }

    handleLogin = async (event) =>
    {
        event.preventDefault();

        var loggedIn = await login(this.state.username, this.state.password);
        if (loggedIn)
        {
            this.doRedirect();
        }
        else
        {
            this.setState({
                incorrectCreds: true,
            });
        }
    }

    doRedirect = () =>
    {
        // Redirect to next URL
        var query = queryString.parse(this.props.location.search); // automatically decodes URI components
        if (query.next)
        {
            this.props.history.push(query.next);
        }
        else
        {
            this.props.history.push("/");
        }
    }

    async componentDidMount()
    {
        // Immediately redirect is user is already logged in
        var user = await getLoggedInUser();
        if (user != null)
        {
            this.doRedirect();
        }
        else
        {
            // Set loginChecked flag so the page renders
            this.setState({
                loginChecked: true
            })
        }
    }

    render()
    {
        document.title = 'Rothar - Login';
        if (!this.state.loginChecked)
        {
            return (null);
        }

        return (
            <form style={{width: '100vw'}} onSubmit={this.handleLogin}>

                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    width={1}
                    height={1}>

                    <Box mb={2}>
                        <img src={evoLogo} alt='Evo Logo' height='128px'/>
                    </Box>

                    <Box m={2}>
                        <Typography variant='h5'>
                            Rothar Dashboard
                        </Typography>
                    </Box>

                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        width='400px'>

                        <Box m={1} width={1}>
                            <TextField
                                variant="filled"
                                label='Username'
                                fullWidth
                                error={this.state.incorrectCreds}
                                name='username'
                                value={this.state.username}
                                onChange={this.handleInputChange}/>
                        </Box>

                        <Box m={1} width={1}>
                            <TextField
                                variant="filled"
                                type="password"
                                label='Password'
                                fullWidth
                                error={this.state.incorrectCreds}
                                name='password'
                                value={this.state.password}
                                onChange={this.handleInputChange}/>
                        </Box>

                        <Box m={2} width={1}>
                            <Button
                                fullWidth
                                variant='contained'
                                type='submit'
                                color='primary'>
                                Login
                            </Button>
                        </Box>

                    </Box>

                </Box>

            </form>
        )
    }
}

export default withRouter(Login)