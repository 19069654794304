import React, { Component } from 'react';

import Box from '@material-ui/core/Box';

import { Marker } from 'react-leaflet';
import L from 'leaflet';

import BreadcrumbAppBar from '../util/BreadcrumbAppBar';

import ParkadeMap from '../parkade_map/ParkadeMap';
import CoordinateHistory from '../parkade_map/CoordinateHistory';

import RotharConnect from '../rothar_connect/RotharConnect';
import { QueryMessages } from '../rothar_connect/RotharConnectMsgs';
import PeriodicSend from '../rothar_connect/PeriodicSend';
import { getParkadeProxColor } from '../rothar_connect/RotharConnectUtils';

import NMEA from './NMEA';
import APIs from '../api/APIs';

import EvoCar from './img/EvoCar.png';

const DEFAULT_LAT = 49.255136;
const DEFAULT_LNG = -122.998764;

const ZOOM_DEFAULT = 12;

const GPS_RATE_DEFAULT_HZ = 5; // 5 GPS coordinates per second
const QUERY_STATE_INTERVAL_MS = 500;
const QUERY_TILT_INTERVAL_MS = 500;

const SPEED_DEFAULT = 0;
const SPEED_MAX = 50; // [m/s]

const HEADING_DEFAULT = 90;

/**
 * Properties:
 *      width: int
 *      height: int
 *      flexGrow: int
 */
class GPSSim extends Component
{
    state =
    {
        mapCenter: [DEFAULT_LAT, DEFAULT_LNG],
        mapZoom: ZOOM_DEFAULT,

        speed: SPEED_DEFAULT,
        heading: HEADING_DEFAULT,

        parkades: [],

        alertVisible: false,
        alertDuration: 0,
        alertMsg: '',
    }

    constructor(props)
    {
        super(props);
        this.connectSend = React.createRef();
        this.coordinateHistory = React.createRef();
        this.updateGPS(DEFAULT_LAT, DEFAULT_LNG);
    }

    refreshParkades = async () =>
    {
        // Retrieve parkades
        var parkadesResp = await fetch(APIs.PARKADES.PARKADES);
        var parkades = await parkadesResp.json();

        this.setState({
            parkades: parkades.sort((a, b) => a.name.localeCompare(b.name))
        });
    }

    calcCurrentSpeed = () =>
    {
        var speed = 0;
        if (this.evoState && this.evoStatePrev)
        {
            var prev = L.latLng(this.evoStatePrev.lat, this.evoStatePrev.lng);
            var here = L.latLng(this.evoState.lat, this.evoState.lng);
            var distance = prev.distanceTo(here); // [m]
            var time = (this.evoState.datetime.getTime() - this.evoStatePrev.datetime.getTime()) / 1000; // [s]
            if (time !== 0)
            {
                speed = (distance / time);
            }
        }

        this.evoStatePrev = {
            lat: this.evoState.lat,
            lng: this.evoState.lng,
            datetime: this.evoState.datetime
        };

        return speed;
    }

    updateGPS = (lat, lng) =>
    {
        this.evoState = {
            lat: lat,
            lng: lng,
            datetime: new Date()
        };
        var speed = Math.min(this.calcCurrentSpeed(), SPEED_MAX);

        var nmeaRMC = NMEA.RMC(this.evoState.datetime, this.evoState.lat, this.evoState.lng, speed, this.state.heading);
        this.connectSend.current?.updateMessage(nmeaRMC);
    }

    handleRotharConnectQueryResponse = (msgType, dataObj) =>
    {
        if (msgType === QueryMessages.STATE)
        {
            this.coordinateHistory.current?.add([dataObj.gpsLat, dataObj.gpsLng], getParkadeProxColor(dataObj.currentProximity, dataObj.bikePresent));
        }
    }

    // handleRotharConnectClearLocations = () =>
    // {
    //     this.coordinateHistory.current?.clear();
    // }

    handleEvoDrag = (event) =>
    {
        var latLng = event.target.getLatLng();
        this.updateGPS(latLng.lat, latLng.lng);
    }

    async componentDidMount()
    {
        await this.refreshParkades();
    }

    render()
    {
        document.title = 'Rothar - GPS Simulator';

        return (
            <Box
                display='flex'
                width={1}
                minHeight={1}
                flexDirection='column'>

                <BreadcrumbAppBar>
                </BreadcrumbAppBar>

                <Box
                    display='flex'
                    width={1}
                    height={1}
                    pt={8}
                    flexDirection='row'>

                    <ParkadeMap
                        mapCenter={this.state.mapCenter}
                        mapZoom={this.state.mapZoom}
                        parkades={this.state.parkades}
                        selectedId={this.state.selectedId}
                        addMode={this.state.addMode}>

                        <CoordinateHistory ref={this.coordinateHistory}>
                        </CoordinateHistory>

                        <Marker
                            key='evo-marker'
                            position={[this.evoState.lat, this.evoState.lng]}
                            draggable={true}
                            onDrag={this.handleEvoDrag}
                            icon={L.icon({
                                iconUrl: EvoCar,
                                iconSize: [128, 80],
                                iconAnchor: [64, 40]
                            })} />

                    </ParkadeMap>

                    <Box
                        display='flex'
                        width='600px'
                        flexDirection='column'>

                        <RotharConnect
                            onQueryResponse={this.handleRotharConnectQueryResponse}>

                            <PeriodicSend
                                key='queryState'
                                initMessage={QueryMessages.STATE}
                                messageInterval={QUERY_STATE_INTERVAL_MS} />

                            <PeriodicSend
                                key='queryTilt'
                                initMessage={QueryMessages.TILT}
                                messageInterval={QUERY_TILT_INTERVAL_MS} />

                            <PeriodicSend
                                ref={this.connectSend}
                                key='gps'
                                messageInterval={1000 / GPS_RATE_DEFAULT_HZ} />

                        </RotharConnect>
                    
                    </Box>

                </Box>

            </Box>
        );
    }
}

export default GPSSim;