import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import APIs from '../api/APIs';
import { fetchWithCSRF } from '../api/APIUtils';

/**
 * Properties:
 *      handleNewMainUnits: () => {}
 *      handleNewTiltModules: () => {}
 *      width: string
 */
class DevicesAddForm extends Component
{
    state = {
        mainUnitsValidationErrors: {
            num: null,
            hwVersion: null,
        },
        tiltModulesValidationErrors: {
            num: null,
            hwVersion: null
        },
        mainUnitsAddEnabled: true,
        tiltModulesAddEnabled: true,
        serialNumbers: '',
    }

    constructor(props) {
        super(props);
        this.mainUnits = {
            num: React.createRef(),
            hwVersion: React.createRef()
        };
        this.tiltModules = {
            num: React.createRef(),
            hwVersion: React.createRef()
        }
    }

    checkValidationErrors = (validationErrors) =>
    {
        var validationPass = true;
        for (var key in validationErrors)
        {
            if (validationErrors[key] != null)
            {
                validationPass = false;
            }
        }
        return validationPass;
    }

    genMainUnits = async (num, hwVersion) =>
    {
        var body = new FormData();
        body.append('num', num);
        body.append('hw_version', hwVersion);
    
        var resp = await fetchWithCSRF(APIs.DEVICES.GEN_MAIN_UNITS, { method: 'POST', body: body});
        if (resp.status === 200)
        {
            var data = await resp.json();
            this.setState({
                serialNumbers: data.serial_numbers.join('\n'),
            });
            this.props.handleNewMainUnits();
        }

        this.setState({
            mainUnitsAddEnabled: true,
        })
    }

    genTiltModules = async (num, hwVersion) =>
    {
        var body = new FormData();
        body.append('num', num);
        body.append('hw_version', hwVersion);
    
        var resp = await fetchWithCSRF(APIs.DEVICES.GEN_TILT_MODULES, { method: 'POST', body: body});
        if (resp.status === 200)
        {
            var data = await resp.json();
            this.setState({
                serialNumbers: data.serial_numbers.join('\n'),
            });
            this.props.handleNewTiltModules();
        }

        this.setState({
            tiltModulesAddEnabled: true,
        })
    }

    handleMainUnitsSubmit = async (event) =>
    {
        event.preventDefault();

        this.setState({
            serialNumbers: 'Adding Devices...',
            mainUnitsAddEnabled: false,
        });

        var validationErrors = {...this.state.mainUnitsValidationErrors};
        var num = parseInt(this.mainUnits.num.current.value);
        var hwVersion = this.mainUnits.hwVersion.current.value;
        validationErrors.num = (num < 1 || num > 100) ? '': null;
        validationErrors.hwVersion = (hwVersion < 0 || hwVersion > 99) ? '': null;
        this.setState({mainUnitsValidationErrors: validationErrors})

        if (this.checkValidationErrors(validationErrors))
        {
            await this.genMainUnits(num, hwVersion);
        }
    }

    handleTiltModulesSubmit = async (event) =>
    {
        event.preventDefault();

        this.setState({
            serialNumbers: 'Adding Devices...',
            tiltModulesAddEnabled: false,
        });

        var validationErrors = {...this.state.tiltModulesValidationErrors};
        var num = parseInt(this.tiltModules.num.current.value);
        var hwVersion = this.tiltModules.hwVersion.current.value;
        validationErrors.num = (num < 1 || num > 100) ? '': null;
        validationErrors.hwVersion = (hwVersion < 0 || hwVersion > 99) ? '': null;
        this.setState({tiltModulesValidationErrors: validationErrors})

        if (this.checkValidationErrors(validationErrors))
        {
            await this.genTiltModules(num, hwVersion);
        }
    }

    render()
    {
        return (
            <Paper style={{display: 'flex', flexDirection: 'column', width: this.props.width}}>
                <Box m={2}><Typography variant='h6'>Add Devices</Typography></Box>

                <Divider/>

                <Box display='flex' flexDirection='column' p={2} mb={1}>
                    <Box mb={1}><Typography variant='overline'><b>Main Units</b></Typography></Box>
                    <Box display='flex' flexDirection='row' component='form' onSubmit={this.handleMainUnitsSubmit}>
                        <TextField variant='filled' style={{flex: 1, marginRight: '16px'}}
                            inputRef={this.mainUnits.num}
                            label='Qty'
                            size='small'
                            error={this.state.mainUnitsValidationErrors.num != null}
                            helperText={this.state.mainUnitsValidationErrors.num}
                            name='mainUnits.num'
                            defaultValue={1}
                            inputProps={{
                                step: 1,
                                min: 1,
                                max: 100,
                                type: 'number'
                            }}/>
                        <TextField variant='standard' style={{flex: 1, marginRight: '16px'}}
                                inputRef={this.mainUnits.hwVersion}
                                label='HW Version'
                                size='small'
                                fullWidth
                                error={this.state.mainUnitsValidationErrors.hwVersion != null}
                                helperText={this.state.mainUnitsValidationErrors.hwVersion}
                                name='mainUnits.hwVersion'
                                defaultValue={0}
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 99,
                                    type: 'number'
                                }}/>
                        <Button variant='contained' type='submit' color='primary' disabled={!this.state.mainUnitsAddEnabled}>Add</Button>
                    </Box>
                </Box>

                <Divider/>

                <Box display='flex' flexDirection='column' p={2} mb={1}>
                    <Box mb={1}><Typography variant='overline'><b>Tilt Modules</b></Typography></Box>
                    <Box display='flex' flexDirection='row' component='form' onSubmit={this.handleTiltModulesSubmit}>
                        <TextField variant='filled' style={{flex: 1, marginRight: '16px'}}
                            inputRef={this.tiltModules.num}
                            label='Qty'
                            size='small'
                            error={this.state.tiltModulesValidationErrors.num != null}
                            helperText={this.state.tiltModulesValidationErrors.num}
                            name='tiltModules.num'
                            defaultValue={1}
                            inputProps={{
                                step: 1,
                                min: 1,
                                max: 100,
                                type: 'number'
                            }}/>
                        <TextField variant='standard' style={{flex: 1, marginRight: '16px'}}
                                inputRef={this.tiltModules.hwVersion}
                                label='HW Version'
                                size='small'
                                fullWidth
                                error={this.state.tiltModulesValidationErrors.hwVersion != null}
                                helperText={this.state.tiltModulesValidationErrors.hwVersion}
                                name='tiltModules.hwVersion'
                                defaultValue={0}
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 99,
                                    type: 'number'
                                }}/>
                        <Button variant='contained' type='submit' color='primary' disabled={!this.state.tiltModulesAddEnabled}>Add</Button>
                    </Box>
                </Box>

                <Divider/>

                <Box m={2}><Typography variant='overline'><b>Generated Serial Numbers</b></Typography></Box>

                <Box flex={1} px={2} mb={3} minHeight={0}>
                    <Paper style={{height: '100%', padding: '16px', overflow: 'auto'}} variant='outlined'>
                        <Typography variant='body1' style={{fontFamily: 'monospace'}} component={'span'}>
                            <pre style={{ fontFamily: 'inherit', margin: 0 }}>
                                {this.state.serialNumbers}
                            </pre>
                        </Typography>
                    </Paper>
                </Box>

            </Paper>
        )
    }
}

export default DevicesAddForm;