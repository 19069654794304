var routes = {
    '/'                       : 'Rothar Dashboard',
    '/devices'                : 'Devices',
    '/devices/main_units'     : 'Main Units',
    '/devices/tilt_modules'   : 'Tilt Modules',
    '/installations'          : 'Installations',
    '/installations/vehicles' : 'Installed Vehicles',
    '/installations/events'   : 'Event Log',
    '/releases'               : 'Releases',
    '/releases/main_firmware' : 'Main Firmware',
    '/releases/parkades'      : 'Parkades',
    '/gps_sim'                : 'GPS Simulator',
    '/parkades'               : 'Parkade Manager',
    '/logs'                   : 'Logs',
    '/logs/log_uploads'       : 'Log Uploads',
};

export default routes;
