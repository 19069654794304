import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';

import APIs from '../api/APIs';
import { fetchWithCSRF } from '../api/APIUtils';

import './ParkadeDetails.css';

class ParkadeDetails extends Component
{
    state = {
        prevEditMode: false,
        validationErrors: {
            name: null,
            centerLat: null,
            centerLng: null,
            width: null,
            height: null,
            rotation: null,
            deltaHeading: null,
            alertHeading: null,
            alertHeadingTolerance: null
        }
    }

    constructor (props)
    {
        super(props);
        this.nameField = React.createRef();
    }

    componentDidMount()
    {
        if (this.props.editMode)
        {
            this.nameField.focus();
        }
    }

    componentDidUpdate()
    {
        if (this.props.editMode && !this.state.prevEditMode)
        {
            this.nameField.focus();
        }

        if (this.props.editMode !== this.state.prevEditMode)
        {
            this.setState({
                prevEditMode: this.props.editMode
            });
        }
    }

    saveParkade = async () =>
    {
        var headers = {
            'Content-Type': 'application/json'
        };

        var resource = APIs.PARKADES.PARKADES;
        var method = 'POST';
        if (this.props.parkade.id)
        {
            // Parkade already exists so use PUT instead
            resource = APIs.PARKADES.PARKADE(this.props.parkade.id);
            method = 'PUT';
        }

        var resp = await fetchWithCSRF(resource, { method: method, headers: headers, body: JSON.stringify(this.props.parkade)});

        if (resp.status === 200 || resp.status === 201) //  HTTP 200 OK (for updating existing parkade) or HTTP 201 Created (for new parkade)
        {
            var respJson = await resp.json();
            this.props.showAlert(1000, 'Parkade Saved!');
            this.props.handleSave(respJson.id);
        }
        else
        {
            this.props.showAlert(5000, 'ERROR: Unable to save parkade!');
        }
    }

    handleInputChange = (event) =>
    {
        var parkade = {...this.props.parkade};
        
        var target = event.target;
        switch(target.name)
        {
            case 'evoParking': parkade.evo_parking = target.checked; break;
            case 'name': parkade.name = target.value; break;
            case 'region': parkade.region = target.value; break;
            case 'address': parkade.address = target.value; break;
            case 'centerLat': parkade.center_lat = target.value; break;
            case 'centerLng': parkade.center_lng = target.value; break;
            case 'width': parkade.width = target.value; break;
            case 'height': parkade.height = target.value; break;
            case 'rotation': parkade.rotation = target.value; break;
            case 'deltaHeading': parkade.delta_heading = target.value; break;
            case 'alertHeading': parkade.alert_heading = target.value; break;
            case 'alertHeadingTolerance': parkade.alert_heading_tolerance = target.value; break;
            default:
                break;
        }
        this.props.handleUpdate(event, parkade);
    }

    handleEditClick = (event) =>
    {
        this.props.handleEdit(event);
        this.setState({
            pendingFocus: true
        });
    }

    handleCancelClick = (event) =>
    {
        this.props.handleCancel(event);
    }

    handleSave = async (event) =>
    {
        // Prevent page refresh
        event.preventDefault();

        // Perform validation
        var validationErrors = {...this.state.validationErrors};
        var validationPass = true;

        var centerLat = parseFloat(this.props.parkade.center_lat);
        var centerLng = parseFloat(this.props.parkade.center_lng);
        var width = parseFloat(this.props.parkade.width);
        var height = parseFloat(this.props.parkade.height);
        var rotation = parseFloat(this.props.parkade.rotation);
        var deltaHeading = parseFloat(this.props.parkade.delta_heading);
        var alertHeading = parseFloat(this.props.parkade.alert_heading);
        var alertHeadingTolerance = parseFloat(this.props.parkade.alert_heading_tolerance);

        validationErrors.name = (this.props.parkade.name.length === 0) ? 'Name cannot be empty': null;
        validationErrors.centerLat = (centerLat < -90 || centerLat > 90) ? '': null;
        validationErrors.centerLng = (centerLng < -180 || centerLng > 180) ? '': null;
        validationErrors.width = (width < 0) ? '': null;
        validationErrors.height = (height < 0) ? '': null;
        validationErrors.rotation = (rotation < -90 || rotation > 90) ? '': null;
        validationErrors.deltaHeading = (deltaHeading < -180 || deltaHeading > 180) ? '': null;
        validationErrors.alertHeading = (alertHeading < 0 || alertHeading > 360) ? '': null;
        validationErrors.alertHeadingTolerance = (alertHeadingTolerance < 0 || alertHeadingTolerance > 180) ? '': null;

        for (var key in validationErrors)
        {
            if (validationErrors[key] != null)
            {
                validationPass = false;
            }
        }

        this.setState({
            validationErrors: validationErrors
        });

        if (validationPass)
        {
            await this.saveParkade();
        }
    }

    handleAlertClose = (event) =>
    {
        this.setState({
            showAlert: false
        });
    }

    render()
    {
        var editButton;
        var cancelSaveButtons;

        if (this.props.editMode)
        {
            cancelSaveButtons = (
                <Box my={3}>
                    <Grid container justify='flex-end' spacing={2}>
                        <Grid item><Button color='secondary' onClick={this.handleCancelClick}>Cancel</Button></Grid>
                        <Grid item><Button variant='contained' type='submit' color='primary'>Save</Button></Grid>
                    </Grid>
                </Box>
            );
        }
        else if(this.props.writeAccess)
        {
            editButton = (
                <Box my={2}>
                    <Grid container justify='flex-end'>
                        <Fab
                            color='primary'
                            aria-label='edit'
                            size='medium'
                            onClick={this.handleEditClick}>
                            <EditIcon />
                        </Fab>
                    </Grid>
                </Box>
            )
        }

        return (
            <div className='ParkadeDetails-content'>
                <Box my={2}>
                    <Typography
                        variant="h6">
                        Parkade Details
                    </Typography>
                </Box>

                <form style={{margin: 0}} action='' onSubmit={this.handleSave}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        disabled={!this.props.editMode}
                                        checked={this.props.parkade.evo_parking}
                                        onChange={this.handleInputChange}
                                        name="evoParking"
                                        color="primary"/>
                                    }
                                    label="Evo Parking"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant='filled'
                                inputRef={input => this.nameField = input}
                                label='Name'
                                size='small'
                                fullWidth
                                required
                                disabled={!this.props.editMode}
                                error={this.state.validationErrors.name != null}
                                helperText={this.state.validationErrors.name}
                                name='name'
                                value={this.props.parkade.name}
                                onChange={this.handleInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant='filled'
                                label='Region'
                                select
                                size='small'
                                fullWidth
                                required
                                disabled={!this.props.editMode}
                                name='region'
                                value={this.props.parkade.region}
                                onChange={this.handleInputChange}>
                                    {this.props.regions.map(region => 
                                        <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                                    )}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant='filled'
                                label='Address'
                                size='small'
                                fullWidth
                                disabled={!this.props.editMode}
                                name='address'
                                value={this.props.parkade.address ?? ''}
                                onChange={this.handleInputChange}/>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField variant='filled'
                                label='Center (latitude)'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 0.00001,
                                    min: -90,
                                    max: 90,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='centerLat'
                                value={this.props.parkade.center_lat}
                                onChange={this.handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant='filled'
                                label='Center (longitude)'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 0.00001,
                                    min: -180,
                                    max: 180,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='centerLng'
                                value={this.props.parkade.center_lng}
                                onChange={this.handleInputChange}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField variant='filled'
                                label='Width (m)'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='width'
                                value={this.props.parkade.width}
                                onChange={this.handleInputChange}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField variant='filled'
                                label='Height (m)'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='height'
                                value={this.props.parkade.height}
                                onChange={this.handleInputChange}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField variant='filled'
                                label='Rotation (deg)'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 1,
                                    min: -90,
                                    max: 90,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='rotation'
                                value={this.props.parkade.rotation}
                                onChange={this.handleInputChange}/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField variant='filled'
                                label='Required Heading Change'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 1,
                                    min: -180,
                                    max: 180,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='deltaHeading'
                                value={this.props.parkade.delta_heading}
                                onChange={this.handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant='filled'
                                label='Alert Heading'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 360,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='alertHeading'
                                value={this.props.parkade.alert_heading}
                                onChange={this.handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant='filled'
                                label='Alert Heading Tolerance'
                                size='small'
                                fullWidth
                                required
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 180,
                                    type: 'number'
                                }}
                                disabled={!this.props.editMode}
                                name='alertHeadingTolerance'
                                value={this.props.parkade.alert_heading_tolerance}
                                onChange={this.handleInputChange}/>
                        </Grid>
                    </Grid>

                    {editButton}
                    {cancelSaveButtons}

                </form>
            </div>
        )
    }
}

export default ParkadeDetails
