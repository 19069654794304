import APIs from '../api/APIs';
import { fetchWithCSRF } from '../api/APIUtils';

async function getLoggedInUser()
{
    var resp = await fetch(APIs.AUTH.LOGIN);
    if (resp.status === 200)
    {
        return await resp.json();
    }
    return null;
}

async function login(username, password)
{
    var body = new FormData();
    body.append('username', username);
    body.append('password', password);

    var resp = await fetchWithCSRF(APIs.AUTH.LOGIN, { method: 'POST', body: body});
    return (resp.status === 200);
}

async function logout(redirect = '/')
{
    await fetchWithCSRF(APIs.AUTH.LOGOUT, {method: 'POST'});
    window.location.href = redirect;
}

export { getLoggedInUser, login, logout }