const TALKER_ID              = 'GN'
const SPEED_DECIMAL_PLACES   = 3;
const HEADING_DECIMAL_PLACES = 2;

var NMEA =
{
    coordToString: function(coord)
    {
        var abs = Math.abs(coord);
        var degrees = Math.floor(abs);
        var minutesDec = (abs - degrees) * 60;
        var minutesInt = Math.floor(minutesDec);
        var minutesFrac = Math.round((minutesDec - minutesInt) * 100000);

        return degrees.toString()
                + minutesInt.toString().padStart(2, '0')
                + '.'
                + minutesFrac.toString().padStart(5, '0');
    },

    calcChecksum: function(sentence)
    {
        var checksum = 0;
        for(var i = 0; i < sentence.length; i++)
        {
            checksum ^= sentence.charCodeAt(i);
        }
        return checksum.toString(16).toUpperCase();
    },

    RMC: function(datetime, latitude, longitude, speed, heading)
    {
        // Time
        var hours = datetime.getUTCHours();
        var minutes = datetime.getUTCMinutes();
        var seconds = datetime.getUTCSeconds();
        var milliseconds = datetime.getUTCMilliseconds();
        var rmcTime = hours.toString().padStart(2, '0')
                        + minutes.toString().padStart(2, '0')
                        + seconds.toString().padStart(2, '0')
                        + '.'
                        + Math.round(milliseconds / 10).toString().padStart(2, '0');

        // Status
        var status = 'A';

        // Latitude
        var rmcLatNum = NMEA.coordToString(latitude);
        var rmcLatDir = latitude >= 0 ? 'N' : 'S';

        // Longitude
        var rmcLngNum = NMEA.coordToString(longitude);
        var rmcLngDir = longitude >= 0 ? 'E' : 'W';

        // Speed
        var rmcSpeed = speed.toFixed(SPEED_DECIMAL_PLACES);

        // Heading
        var rmcHeading = heading.toFixed(HEADING_DECIMAL_PLACES);

        // Date
        var date = datetime.getUTCDate();
        var month = datetime.getUTCMonth() + 1; // getUTCMonth() returns [0 - 11]
        var year = datetime.getUTCFullYear();
        var rmcDate = date.toString().padStart(2, '0')
                        + month.toString().padStart(2, '0')
                        + (year - (Math.floor(year / 100) * 100)).toString().padStart(2, '0');

        // Nav Status
        var navStatus = 'A';

        // Checksum
        var sentence = `${TALKER_ID}RMC,${rmcTime},${status},${rmcLatNum},${rmcLatDir},${rmcLngNum},${rmcLngDir},${rmcSpeed},${rmcHeading},${rmcDate},,,${navStatus}`;
        var checksum = NMEA.calcChecksum(sentence);

        return `$${sentence}*${checksum}\r\n`;
    }
};

export default NMEA;