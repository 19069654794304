import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import dateFormat from 'dateformat';

import FilterDataGrid from '../util/FilterDataGrid';

const TIMESTAMP_FORMAT = 'yyyy-mm-dd h:MM:ss TT';

var dateFormatter = (params) => (params.value === null) ? '-' : dateFormat(params.value, TIMESTAMP_FORMAT)
var nullFormatter = (params) => (params.value === null || params.value === '') ? '-' : params.value;

const mainUnitColumns = [
    { field: 'serial_number', headerName: 'Serial Number', flex: 1, valueFormatter: nullFormatter },
    { field: 'tilt_module_a_sn', headerName: 'Tilt Module A', flex: 1, valueFormatter: nullFormatter },
    { field: 'tilt_module_b_sn', headerName: 'Tilt Module B', flex: 1, valueFormatter: nullFormatter },
    { field: 'mac_address', headerName: 'MAC Address', flex: 1, valueFormatter: nullFormatter },
    { field: 'hw_version', headerName: 'HW Version', flex: 1, valueFormatter: nullFormatter },
    { field: 'fw_version', headerName: 'FW Version', flex: 1, valueFormatter: nullFormatter },
    {
        field: 'fw_flashed_version', headerName: 'Flashed FW Version', flex: 1, filterable: false, disableReorder: true, disableExport: true, disableColumnMenu: true, hideSortIcons: true,
        renderCell: (params) => {
            if (params.row.fw_version === params.row.fw_flashed_version)
            {
                return (
                    <Typography variant='body'>
                        {params.row.fw_flashed_version}
                    </Typography>
                );
            }
            else
            {
                return (
                    <Typography variant='body' color="secondary">
                        <b>{params.row.fw_flashed_version}</b>
                    </Typography>
                );
            }
        },
    },
    { field: 'parkades_version', headerName: 'PKDS Version', flex: 1, valueFormatter: nullFormatter },
    {
        field: 'parkades_flashed_version', headerName: 'Flashed PKDS Version', flex: 1, filterable: false, disableReorder: true, disableExport: true, disableColumnMenu: true, hideSortIcons: true,
        renderCell: (params) => {
            if (params.row.parkades_version === params.row.parkades_flashed_version)
            {
                return (
                    <Typography variant='body'>
                        {params.row.parkades_flashed_version}
                    </Typography>
                );
            }
            else
            {
                return (
                    <Typography variant='body' color="secondary">
                        <b>{params.row.parkades_flashed_version}</b>
                    </Typography>
                );
            }
        },
    },
    { field: 'manufacture_date', headerName: 'Manufacture Date', flex: 1, valueFormatter: dateFormatter},
];

const tiltModuleColumns = [
    { field: 'serial_number', headerName: 'Serial Number', flex: 1, valueFormatter: nullFormatter },
    { field: 'ble_address', headerName: 'BLE Address', flex: 1, valueFormatter: nullFormatter },
    { field: 'hw_version', headerName: 'HW Version', flex: 1, valueFormatter: nullFormatter },
    { field: 'fw_version', headerName: 'FW Version', flex: 1, valueFormatter: nullFormatter },
    { field: 'manufacture_date', headerName: 'Manufacture Date', flex: 1, valueFormatter: dateFormatter},
];

class DevicesLists extends Component
{
    render()
    {
        const path = this.props.match.path;

        return (
            <Box display='flex' flexDirection='column' flex={this.props.flex}>
                <Box ml={2} mr={2} mt={2}>
                    <Paper>
                        <Tabs
                            value={this.props.location.pathname}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='fullWidth'>

                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/main_units`} value={`${this.props.match.url}/main_units`} label='Main Units' />
                            <Tab component={NavLink} activeClassName="Mui-selected" to={`${this.props.match.url}/tilt_modules`} value={`${this.props.match.url}/tilt_modules`} label='Tilt Modules' />

                        </Tabs>
                    </Paper>
                </Box>

                <Box flex={1} m={2}>
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${path}/main_units`}/>
                        </Route>
                        <Route path={`${path}/main_units`}>
                            <FilterDataGrid key='Main Units' title='Rothar - Devices - Main Units' data={this.props.mainUnits} columns={mainUnitColumns}/>
                        </Route>
                        <Route path={`${path}/tilt_modules`}>
                            <FilterDataGrid key='Tilt Modules' title='Rothar - Devices - Tilt Modules' data={this.props.tiltModules} columns={tiltModuleColumns}/>
                        </Route>
                    </Switch>
                </Box>
            </Box>
        )
    }
}

export default withRouter(DevicesLists);
