import React, { Component } from 'react';

import { DataGrid } from '@mui/x-data-grid';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

var escapeRegExp = (value) =>
{
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

var filterData = (data, searchValue) =>
{
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredData = data.filter((row) => {
        return Object.keys(row).some((field) => {
            if (row[field] === null || row[field] === undefined)
            {
                return false;
            }
            return searchRegex.test(row[field].toString());

        });
    });
    return filteredData;
}

/**
 * Properties:
 *      title: string
 *      data: [Object]
 *      columns: [GridColDef]
 */
class FilterDataGrid extends Component
{
    state = {
        filteredData: [],

        searchValue: '',
    }

    updateFilteredData = (searchValue) =>
    {
        var filteredData = filterData(this.props.data, searchValue);
        this.setState({
            searchValue: searchValue,
            filteredData: filteredData,
        });
    }
    handleSearchValueChange = (event) =>
    {
        this.updateFilteredData(event.target.value);
    }

    handleClearSearch = (event) =>
    {
        this.updateFilteredData('');
    }

    async componentDidMount()
    {
        this.updateFilteredData('');
    }

    async componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps.data !== this.props.data)
        {
            this.updateFilteredData('');
        }
    }

    render()
    {
        document.title = this.props.title;

        return (
            <Box display='flex' flexDirection='column' height={1}>
                <Box mb={2}>
                    <TextField
                        variant='standard'
                        fullWidth
                        value={this.state.searchValue}
                        onChange={this.handleSearchValueChange}
                        placeholder='Search…'
                        InputProps={{
                            startAdornment: <SearchIcon fontSize='small' />,
                            endAdornment: (
                                <IconButton
                                    title='Clear'
                                    aria-label='Clear'
                                    size='small'
                                    style={{ visibility: this.state.searchValue ? 'visible' : 'hidden' }}
                                    onClick={this.handleClearSearch}>
                                    <ClearIcon fontSize='small' />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
                <DataGrid
                    checkboxSelection={true}
                    onRowClick={(params, event) => {
                        event.defaultMuiPrevented = true;
                    }}
                    rows={this.state.filteredData}
                    columns={this.props.columns} />
            </Box>
        );
    }
}

export default FilterDataGrid;