import React, { Component } from 'react';
import { withRouter, Redirect } from "react-router-dom";

import PropTypes from 'prop-types';

import { getLoggedInUser } from './AuthUtils';

class AuthChecker extends Component
{
    state = {
        loggedIn : null, // null indicates we haven't received a response yet, true of false will indicate logged in or not
        user     : null,
    }

    async componentDidMount()
    {
        var user = await getLoggedInUser();
        this.setState({
            loggedIn: (user !== null && user !== undefined),
            user: user
        })
    }

    componentWillUnmount()
    {
        this.setState({loggedIn: null});
    }

    render()
    {
        if (this.state.loggedIn == null)
        {
            return (null);
        }
        else if (this.state.loggedIn)
        {
            return (
                <>
                {React.cloneElement(this.props.children, { user: this.state.user }) /* Pass the user object down to the child as a property */}
                </>
            );
        }
        else
        {
            var redirect = '/login?next=' + encodeURIComponent(this.props.location.pathname);
            return <Redirect to={redirect} />
        }
    }
}

// Enforce only a single child
AuthChecker.propTypes = {
    children: PropTypes.element.isRequired
};

export default withRouter(AuthChecker);
