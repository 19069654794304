import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ParkingIcon from '@material-ui/icons/LocalParking';

import evoParking from '../img/EvoLogo_e.png';

/**
 * List of parkades with a details pane for the selected parkade
 * 
 * Properties:
 *      writeAccess: bool
 *      parkades: [Parkade]
 *      selectedId: int
 *      handleParkadeAdd: (event) => {}
 *      handleParkadeDelete: (event, id) => {}
 *      handleParkadeSelect: (event, id) => {}
 */

class ParkadeList extends Component
{
    renderParkadeListItem = (parkade) =>
    {
        var icon;
        if (parkade.evo_parking)
        {
            icon = (
                <ListItemIcon>
                    <img alt="Evo Parking" src={evoParking} width='24px' />
                </ListItemIcon>
            );
        }
        else
        {
            icon = (
                <ListItemIcon>
                    <ParkingIcon />
                </ListItemIcon>
            );
        }

        var deleteButton;
        if (this.props.writeAccess){
            deleteButton = (
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => this.props.handleParkadeDelete(parkade.id)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            );
        }

        return (
            <ListItem
                key={parkade.id}
                button
                selected={this.props.selectedId === parkade.id}
                onClick={(event) => this.props.handleParkadeSelect(event, parkade.id)}>
                {icon}
                <ListItemText primary={parkade.name} />
                {deleteButton}
            </ListItem>
        )
    }

    render()
    {
        var addButton;
        if(this.props.writeAccess)
        {
            addButton = (
                <IconButton edge="end" color="inherit" aria-label="add" onClick={this.props.handleParkadeAdd}>
                    <AddIcon color='action'/>
                </IconButton>
            )
        }

        return (
            <Box
                display='flex'
                flexDirection='column'
                width={1}
                flexBasis={0}
                flexShrink={1}
                flexGrow={1}>

                <AppBar position='static' style={{background: 'transparent'}}>
                    <Toolbar>
                        <Typography variant='h6' style={{flexGrow: 1}} color='textPrimary'>
                        Parkades
                        </Typography>
                        {addButton}
                    </Toolbar>
                </AppBar>

                <Box
                    flexBasis={0}
                    flexShrink={1}
                    flexGrow={1}
                    overflow='auto'>
                    <List>
                        {this.props.parkades.map(this.renderParkadeListItem)}
                    </List>
                </Box>

            </Box>
        );
    }
}

export default ParkadeList;
