const MERIDIAN_LENGTH         = 20003930; // metres
const EQUATOR_PARALLEL_LENGTH = 40075000; // metres

function rotatePoint(point, theta_deg)
{
    var theta_rad = theta_deg * Math.PI / 180;
    var x_rot = Math.cos(theta_rad) * point[0] - Math.sin(theta_rad) * point[1];
    var y_rot = Math.sin(theta_rad) * point[0] + Math.cos(theta_rad) * point[1];

    return [x_rot, y_rot];
}

function xy_to_latlng(origin_latlng, delta_xy)
{
    var dx = delta_xy[0];
    var dy = delta_xy[1];

    var lat1 = origin_latlng[0];
    var lng1 = origin_latlng[1];

    var latScaler = 180 / MERIDIAN_LENGTH;
    var lngScaler = 360 / (EQUATOR_PARALLEL_LENGTH * Math.cos(lat1 * Math.PI / 180));

    var lat2 = (dy * latScaler) + lat1;
    var lng2 = (dx * lngScaler) + lng1;

    return [lat2, lng2];
}

function parkadeBounds(center, width, height, rotation)
{
    if (isNaN(center[0]) || isNaN(center[1]) || isNaN(width) || isNaN(height) || isNaN(rotation))
    {
        return null;
    }

    var x = width / 2;
    var y = height / 2;

    var A_xy = [-x, y];
    var B_xy = [x, y];
    var C_xy = [x, -y];
    var D_xy = [-x, -y];

    var A_xy_rot = rotatePoint(A_xy, -rotation);
    var B_xy_rot = rotatePoint(B_xy, -rotation);
    var C_xy_rot = rotatePoint(C_xy, -rotation);
    var D_xy_rot = rotatePoint(D_xy, -rotation);

    var A = xy_to_latlng(center, A_xy_rot);
    var B = xy_to_latlng(center, B_xy_rot);
    var C = xy_to_latlng(center, C_xy_rot);
    var D = xy_to_latlng(center, D_xy_rot);

    var bounds = [A, B, C, D];

    return bounds;
}

function destination(start, distance, heading)
{
    if (isNaN(start[0]) || isNaN(start[1]) || isNaN(distance) || isNaN(heading))
    {
        return null;
    }

    var end_xy = rotatePoint([0, distance], -heading);
    var end = xy_to_latlng(start, end_xy);

    return end;
}

export {parkadeBounds, destination}

