import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import APIs from '../api/APIs';
import { fetchWithCSRF } from '../api/APIUtils';

/**
 * Properties:
 *      width: string
 */
class MainFirmwareReleaseForm extends Component
{
    handleSubmit = async (event) =>
    {

    }

    render()
    {
        return (
            <Paper style={{display: 'flex', flexDirection: 'column', width: this.props.width}} component='form' onSubmit={this.handleSubmit}>
                <Box m={2}><Typography variant='h6'>Create Main Firmware Release</Typography></Box>

                <Divider/>
            </Paper>
        )
    }
}

export default MainFirmwareReleaseForm;