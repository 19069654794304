import React, { Component } from 'react';

import Box from '@material-ui/core/Box';

import APIs from '../api/APIs';

import BreadcrumbAppBar from '../util/BreadcrumbAppBar';
import InstallationsLists from './InstallationsLists';
import InstallEventForm from './InstallEventForm';

class InstallationsManager extends Component
{
    state = {
        mainUnits: [],
        vehicles: [],
        events: [],
    }

    refreshMainUnits = async () =>
    {
        var resp = await fetch(`${APIs.DEVICES.MAIN_UNITS}?deep`);
        if (resp.status === 200)
        {
            var mainUnits = await resp.json();
            return mainUnits;
        }
        return [];
    }

    refreshInstallEvents = async () =>
    {
        var resp = await fetch(APIs.DEVICES.INSTALL_EVENTS);
        if (resp.status === 200)
        {
            var installEvents = await resp.json();
            return installEvents;
        }
        return [];
    }

    refreshData = async () =>
    {
        var mainUnits = await this.refreshMainUnits();
        var events = await this.refreshInstallEvents();

        // Convert date strings to date objects
        events = events.map((element) =>
        {
            element.timestamp = new Date(element.timestamp);
            return element;
        });

        // Sort by timestamp
        events.sort((first, second) =>
        {
            return first.timestamp === second.timestamp ? 0 : (first.timestamp > second.timestamp ? -1 : 1)
        });

        // Extract data for vehicles
        var vehicles = mainUnits
            .filter((mainUnit) => (mainUnit.install_events.length > 0 && mainUnit.install_events[0].install))
            .map((mainUnit) => {
                return {
                    id               : mainUnit.install_events[0].id,
                    license_plate    : mainUnit.install_events[0].license_plate,
                    main_unit_sn     : mainUnit.serial_number,
                    tilt_module_a_sn : mainUnit.tilt_module_a ? mainUnit.tilt_module_a.serial_number : null,
                    tilt_module_b_sn : mainUnit.tilt_module_b ? mainUnit.tilt_module_b.serial_number : null,
                    timestamp        : mainUnit.install_events[0].timestamp,
                };
            });

        this.setState({
            mainUnits: mainUnits,
            vehicles: vehicles,
            events: events
        });
    }

    getMainUnit = (serial_number) =>
    {
        return this.state.mainUnits.find((mainUnit) => mainUnit.serial_number === serial_number);
    }

    getVehicle = (serial_number) =>
    {
        return this.state.vehicles.find((vehicle) => vehicle.main_unit_sn === serial_number);
    }

    handleInstallEventAdded = async (installEvent) =>
    {
        await this.refreshData();
    }

    async componentDidMount()
    {
        await this.refreshData();
    }

    render()
    {
        document.title = 'Rothar - Installations';

        return (
            <Box display='flex' flexDirection='column' minHeight={0} width={1}>
                <BreadcrumbAppBar/>

                <Box display='flex' flexDirection='row' width={1} height={1} pt={8}>

                    <InstallationsLists flex={1}
                        vehicles={this.state.vehicles}
                        events={this.state.events} />

                    <InstallEventForm width='400px'
                        getMainUnit={this.getMainUnit}
                        getVehicle={this.getVehicle}
                        handleInstallEventAdded={this.handleInstallEventAdded} />

                </Box>
            </Box>
        )
    }
}

export default InstallationsManager;
