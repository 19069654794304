import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";

import Box from '@material-ui/core/Box';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import routes from './Routes';
import { logout } from '../auth/AuthUtils';

import evoLogo from '../img/EvoLogo_black.png';

class BreadcrumbAppBar extends Component
{
    state = {
    }

    handleLogout = async (event) =>
    {
        logout();
    }

    async componentDidMount()
    {
    }

    render()
    {
        const pathNames = this.props.location.pathname.split('/').filter((x) => x);

        // TODO don't hardcode colours

        return (
            <AppBar position='fixed' style={{zIndex: 1000}}>
                <Toolbar>
                    <Breadcrumbs style={{flex: 1, color: 'black'}}>
                        <Link style={{color: 'black'}} to='/' component={RouterLink}>
                            Rothar Dashboard
                        </Link>
                        {
                            pathNames.map((value, index) => {
                                const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                                if (index === (pathNames.length - 1))
                                {
                                    // Last name (current page)
                                    return (
                                        <Typography key={to}>
                                            {routes[to]}
                                        </Typography>
                                    );
                                }
                                else
                                {
                                    return (
                                        <Link color='inherit' to={to} component={RouterLink} key={to}>
                                            {routes[to]}
                                        </Link>
                                    );
                                }
                            })
                        }
                    </Breadcrumbs>
                    {this.props.children}
                    <Button color='inherit' onClick={this.handleLogout}>Logout</Button>
                </Toolbar>

                <Box
                    height='100%'
                    width='100%'
                    p={1.5}
                    style={{
                        position: 'absolute',
                        margin: 'auto',
                        textAlign: 'center',
                        pointerEvents: 'none',
                    }}>

                    <img
                        src={evoLogo}
                        alt='Evo Logo'
                        height='100%'/>

                </Box>
            </AppBar>
        )
    }
}

export default withRouter(BreadcrumbAppBar)