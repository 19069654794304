import APIs from './APIs';

var csrfToken;

async function fetchCSRFToken()
{
    var response = await fetch(APIs.AUTH.CSRF, {
        credentials: 'include',
    });

    if (response.status === 200)
    {
        var data = await response.json();
        return data.csrf_token;
    }
    return null;
}

function addCSRFHeader(init = {})
{
    // Setup fetch init object
    if (!init) init = {};
    if (!init['headers']) init['headers'] = {};

    // Populate CSRF token header
    if (csrfToken)
    {
        init['headers']['X-CSRFToken'] = csrfToken;
    }

    return init;
}

async function fetchWithCSRF(resource, init = {})
{
    // Fetch new CSRF token if necessary
    if (!csrfToken)
    {
        csrfToken = await fetchCSRFToken();
    }

    // Set CSRF header
    init = addCSRFHeader(init);

    // Send request
    var response = await fetch(resource, init);

    // Check for 403 error (indicates failed CSRF)
    if (response.status === 403)
    {
        // Verify 403 was caused by failed CSRF
        var data = await response.json();
        if (data['csrf_failure'] === true)
        {
            // Refresh CSRF token and re-send
            csrfToken = await fetchCSRFToken();
            init = addCSRFHeader(init);
            return await fetch(resource, init);
        }
    }

    return response;
}

export { fetchWithCSRF };