import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import AuthChecker from './components/auth/AuthChecker';

import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ParkadeManager from './components/parkades/ParkadeManager';
import DevicesManager from './components/devices/DevicesManager';
import LogsManager from './components/logs/LogsManager';
import ReleasesManager from './components/releases/ReleasesManager';
import GPSSim from './components/gps_sim/GPSSim';

// Unused
import InstallationsManager from './components/installations/InstallationsManager';

import './App.css';

const EVO_BLUE = '#00bce2'

class App extends Component
{
    state = {
    }

    theme = createMuiTheme({
        palette: {
            type: 'dark',
            primary: {
                main: EVO_BLUE,
            },
            secondary: {
                main: '#ff5b4f',
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: '8px',
                        backgroundColor: 'rgba(0, 0, 0, 0)'
                    },
                    '*::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        'border-radius': '10px',
                        '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
                        'background-color': '#888888'
                    }
                }
            }
          }
    });

    async componentDidMount()
    {
    }

    render()
    {
        return (
            <ThemeProvider theme={this.theme}>
                <CssBaseline />
                <Router>
                    <Box
                        display='flex'
                        width={1}
                        height={1}>

                        <Switch>
                            <Route exact path="/">
                                <AuthChecker><Dashboard/></AuthChecker>
                            </Route>
                            <Route exact path="/login">
                                <Login />
                            </Route>
                            <Route exact path="/parkades">
                                <AuthChecker><ParkadeManager/></AuthChecker>
                            </Route>
                            <Route path="/devices">
                                <AuthChecker><DevicesManager/></AuthChecker>
                            </Route>
                            <Route path="/logs">
                                <AuthChecker><LogsManager/></AuthChecker>
                            </Route>
                            <Route path="/releases">
                                <AuthChecker><ReleasesManager/></AuthChecker>
                            </Route>
                            <Route path="/gps_sim">
                                <AuthChecker><GPSSim/></AuthChecker>
                            </Route>

                            {/* Unused */}
                            <Route path="/installations">
                                <AuthChecker><InstallationsManager/></AuthChecker>
                            </Route>
                        </Switch>
                    </Box>
                </Router>

            </ThemeProvider>
        );
    }
}

export default App;
